// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import SwiperCore, {Pagination, Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Supermove
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import Space from '../Space';
import Styled from '../Styled';

SwiperCore.use([Pagination, Navigation]);

const SwiperContainer = Styled.View`
  justify-content: space-between;
`;

const PaginationContainer = Styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const PaginationDot = Styled.View`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-horizontal: 6px;
  background-color: ${(props) => (props.isActive ? colors.orange.status : colors.gray.border)};
`;

const SwiperComponent = ({slides = [], activeDotColor, onEnd, swiperRef}) => {
  const [activeDot, setActiveDot] = useState(0);

  return (
    <SwiperContainer>
      <Swiper
        slidesPerView={1}
        onSnapIndexChange={(swiper) => setActiveDot(swiper.visibleSlidesIndexes[0])}
        watchSlidesVisibility
        onReachEnd={onEnd}
        onSwiper={(swiper) => {
          if (swiperRef) {
            swiperRef.current = swiper;
          }
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </Swiper>
      <Space height={24} />
      <PaginationContainer>
        {slides.map((slide, index) => (
          <PaginationDot
            activeDotColor={activeDotColor}
            isActive={activeDot === index}
            key={index}
          />
        ))}
      </PaginationContainer>
    </SwiperContainer>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SwiperComponent.propTypes = {
  onEnd: PropTypes.func,
};

SwiperComponent.defaultProps = {
  onEnd: () => {},
};

export default SwiperComponent;
