/**
 * Single object that we add all other components to. This is used via:
 *
 *   import {Styled} from '@supermove/components';
 *
 *   const Container = Styled.View`
 *     flex: 1;
 *     padding-horizontal: 20px;
 *   `;
 */

// Libraries
import styled from 'styled-components/primitives';

const Styled = (Component) => styled(Component);

export default Styled;
