// Libraries
import React from 'react';

const GoogleMaps = ({size, color}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.6526 0.577412C13.8586 0.324781 12.9925 0.18042 12.1083 0.18042C9.56391 0.18042 7.27218 1.33531 5.73834 3.15786L9.67218 6.46012L14.6526 0.577412Z'
        fill={color || '#1A73E8'}
      />
      <path
        d='M5.73833 3.15784C4.52931 4.60145 3.78946 6.47814 3.78946 8.49919C3.78946 10.0691 4.09623 11.3323 4.61953 12.4691L9.67217 6.46009L5.73833 3.15784Z'
        fill={color || '#EA4335'}
      />
      <path
        d='M12.1263 5.32326C13.8947 5.32326 15.3203 6.74882 15.3203 8.51724C15.3203 9.29318 15.0316 10.015 14.5624 10.5744C14.5624 10.5744 17.0707 7.5789 19.5248 4.67363C18.5143 2.72476 16.7639 1.24506 14.6526 0.577393L9.67218 6.4601C10.2677 5.77438 11.1338 5.32326 12.1263 5.32326Z'
        fill={color || '#4285F4'}
      />
      <path
        d='M12.1263 11.6933C10.3579 11.6933 8.93232 10.2677 8.93232 8.4993C8.93232 7.72336 9.203 7.00156 9.67217 6.46021L4.61954 12.4692C5.4857 14.382 6.92931 15.9339 8.40901 17.8647L14.5624 10.5564C13.9669 11.2602 13.1007 11.6933 12.1263 11.6933Z'
        fill={color || '#FBBC04'}
      />
      <path
        d='M14.4541 19.8857C17.2331 15.5369 20.4632 13.5699 20.4632 8.51732C20.4632 7.12784 20.1203 5.82859 19.5248 4.67371L8.40903 17.8647C8.8782 18.4782 9.36542 19.182 9.83459 19.9038C11.5308 22.5203 11.0617 24.0722 12.1444 24.0722C13.2271 24.0722 12.7579 22.5023 14.4541 19.8857Z'
        fill={color || '#34A853'}
      />
    </svg>
  );
};

const StreetView = ({size, color}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={color || '#FBBC04'}
        d='M15 3C15 2.20435 14.6839 1.44129 14.1213 0.87868C13.5587 0.316071 12.7956 0 12 0C11.2044 0 10.4413 0.316071 9.87868 0.87868C9.31607 1.44129 9 2.20435 9 3C9 3.79565 9.31607 4.55871 9.87868 5.12132C10.4413 5.68393 11.2044 6 12 6C12.7956 6 13.5587 5.68393 14.1213 5.12132C14.6839 4.55871 15 3.79565 15 3ZM10.5 7.5C8.84531 7.5 7.5 8.84531 7.5 10.5V12.75C7.5 13.5797 8.17031 14.25 9 14.25H9.08438L9.60469 18.9141C9.68906 19.6734 10.3313 20.25 11.0953 20.25H12.9094C13.6734 20.25 14.3156 19.6734 14.4 18.9141L14.9156 14.25H15C15.8297 14.25 16.5 13.5797 16.5 12.75V10.5C16.5 8.84531 15.1547 7.5 13.5 7.5H10.5ZM6.20156 18.4781C6.81094 18.3656 7.21875 17.7797 7.10625 17.1703C6.99375 16.5609 6.40781 16.1531 5.79844 16.2656C4.27969 16.5422 2.94375 16.9312 1.95469 17.4281C1.4625 17.6766 1.00312 17.9766 0.651562 18.3469C0.3 18.7266 0 19.2422 0 19.875C0 20.8781 0.726562 21.5672 1.36406 21.9844C2.05312 22.4344 2.97187 22.7953 4.00781 23.0812C6.10313 23.6578 8.925 24 12 24C15.075 24 17.8969 23.6578 19.9875 23.0812C21.0234 22.7953 21.9469 22.4344 22.6312 21.9844C23.2734 21.5672 23.9953 20.8781 23.9953 19.875C23.9953 19.2422 23.6953 18.7266 23.3391 18.3469C22.9875 17.9766 22.5281 17.6766 22.0359 17.4281C21.0516 16.9312 19.7156 16.5422 18.1922 16.2656C17.5828 16.1531 16.9969 16.5609 16.8844 17.1703C16.7719 17.7797 17.1797 18.3656 17.7891 18.4781C19.2047 18.7359 20.3062 19.0781 21.0234 19.4391C21.1734 19.5141 21.2953 19.5844 21.3937 19.65C21.5625 19.7625 21.5625 19.9875 21.3937 20.1C20.9812 20.3672 20.3109 20.6531 19.3781 20.9109C17.5453 21.4219 14.9297 21.75 12 21.75C9.07031 21.75 6.45469 21.4219 4.60781 20.9109C3.675 20.6531 3.00469 20.3672 2.59219 20.1C2.42344 19.9875 2.42344 19.7625 2.59219 19.65C2.69062 19.5844 2.81719 19.5141 2.9625 19.4391C3.67969 19.0781 4.78125 18.7406 6.19687 18.4781H6.20156Z'
      />
    </svg>
  );
};

const Zillow = ({size, color}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.8689 6.93245C15.9794 6.91008 16.0286 6.94363 16.0957 7.01072C16.4765 7.39607 17.6935 8.74908 18.0242 9.11808C18.0856 9.18518 18.0431 9.25829 17.994 9.297C15.5372 11.0414 12.8036 13.5126 11.2805 15.2793C11.2502 15.3189 11.2748 15.3189 11.2994 15.3129C13.9526 14.273 20.1861 12.612 22.9934 12.1484V8.91165L12.0062 1L1 8.90563V12.4443C4.40824 10.6001 12.2943 7.74271 15.8689 6.93245Z'
        fill={color || '#276EF1'}
      />
      <path
        d='M6.93301 20.5297C6.84135 20.5744 6.7667 20.5632 6.693 20.4962L4.64163 18.271C4.58683 18.209 4.58021 18.1755 4.65392 18.0809C6.23945 15.9675 9.47572 12.674 11.5394 11.2822C11.5762 11.2599 11.5639 11.2315 11.5205 11.2487C9.37084 11.8861 3.26019 14.279 1 15.4419V23H22.9944V15.7215C19.9971 16.1851 11.0178 18.6176 6.93301 20.5297Z'
        fill={color || '#276EF1'}
      />
    </svg>
  );
};

const Image = {
  GoogleMaps,
  StreetView,
  Zillow,
};

export default Image;
