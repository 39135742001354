// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {colors} from '@supermove/styles';

// Components
import Styled from '../Styled';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  padding-vertical: 10px;
  padding-horizontal: 20px;
  border-radius: 5px;
  background-color: ${(props) => colors.alpha(props.color, 0.1)}
`;

const Text = Styled.H6`
  flex: 1;
  color: ${(props) => props.color};
`;

/**
 * Creates a new Notification component each time. This way they can all have
 * different defaultProps.
 */
const createNotification = ({color}) => {
  const Notification = ({color, text, style, children}) => (
    <Container color={color} style={style}>
      {React.isValidElement(text) ? (
        React.cloneElement(text, {color})
      ) : (
        <Text color={color}>{text}</Text>
      )}
      {children}
    </Container>
  );

  Notification.propTypes = {
    color: PropTypes.string,
  };

  Notification.defaultProps = {
    color,
  };

  return Notification;
};

// Create the base Notification.
const Notification = createNotification({});

// Additional Components
Notification.Text = Text;

// Types
Notification.Success = createNotification({color: colors.green.status});
Notification.Info = createNotification({color: colors.blue.accentDark});
Notification.Default = createNotification({color: colors.yellow.hover});
Notification.Warning = createNotification({color: colors.orange.status});
Notification.Error = createNotification({color: colors.red.warning});

export default Notification;
