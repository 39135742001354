// Libraries
import {useEffect, useState} from 'react';

// This object is global so it does not cause re-renders.
const zendesk = {
  open: () => {
    if (window.zE) {
      // Opens the Zendesk chat widget:
      // https://developer.zendesk.com/api-reference/widget-messaging/web/core/#open
      window.zE('messenger', 'open');
    }
  },
  show: () => {
    if (window.zE) {
      window.zE('messenger', 'show');
    }
  },
  hide: () => {
    if (window.zE) {
      window.zE('messenger', 'hide');
    }
  },
};

const useZendeskWebWidget = ({widgetKey, isVisible, options}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetKey}`;
    script.async = true;
    script.onload = () => setIsLoaded(true);

    // Set the settings on the global zendesk settings object.
    window.zESettings = {...options};

    // Add and remove the script from the DOM.
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [widgetKey, options]);

  // Once loaded, we let the isVisible flag handle showing and hiding the zendesk widget.
  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (isVisible) {
      zendesk.show();
    } else {
      zendesk.hide();
    }
  }, [isVisible, isLoaded]);

  return zendesk;
};

export default useZendeskWebWidget;
