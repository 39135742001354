// Libraries
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';

// Supermove
import {useMountEffect} from '@supermove/hooks';

// Navigation
import useNavigation from './useNavigation';

// Allows us to get the current value before the other effects run (uses useLayoutEffect).
const useGetValue = (value) => {
  const ref = useRef(value);
  useLayoutEffect(() => {
    ref.current = value;
  });

  return useCallback(() => ref.current, [ref]);
};

const useIsFocused = ({onFocus, onBlur} = {}) => {
  const {navigator} = useNavigation();
  const getNavigator = useGetValue(navigator);
  const [isFocused, setIsFocused] = useState(navigator.isFocused());
  const focusCallback = useCallback(onFocus, []);
  const blurCallback = useCallback(onBlur, []);

  // Call the focus handler on first mount.
  useMountEffect(() => {
    if (focusCallback) {
      focusCallback();
    }
  });

  // Sets up the listeners for focus and blur events.
  useEffect(() => {
    const navigator = getNavigator();
    const listeners = [
      navigator.addListener('willFocus', () => {
        setIsFocused(true);
        if (focusCallback) {
          focusCallback();
        }
      }),
      navigator.addListener('willBlur', () => {
        setIsFocused(false);
        if (blurCallback) {
          blurCallback();
        }
      }),
    ];

    return () => listeners.forEach((listener) => listener.remove());
  }, [getNavigator, focusCallback, blurCallback]);

  return isFocused;
};

export default useIsFocused;
