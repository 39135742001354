// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {ApolloProvider} from '@supermove/graphql';

const App = ({client, children}) => <ApolloProvider client={client}>{children}</ApolloProvider>;

App.propTypes = {
  client: PropTypes.object.isRequired,
};

App.defaultProps = {};

export default App;
