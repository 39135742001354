// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import toast from 'react-hot-toast';

const useToast = ({ToastComponent, message, actionText, handleAction, isCloseable, duration}) => {
  const handleToast = ({actionPayload, message: dynamicMessage} = {}) => {
    toast(
      (toastElement) => (
        <ToastComponent
          message={dynamicMessage || message}
          actionText={actionText}
          handleAction={() => handleAction(actionPayload)}
          handleDismiss={() => toast.dismiss(toastElement.id)}
          handleRemove={() => toast.remove(toastElement.id)}
          isCloseable={isCloseable}
        />
      ),
      {
        duration: duration || 5000, // Takes milliseconds or Infinity
      },
    );
  };

  handleToast.propTypes = {
    actionPayload: PropTypes.object,
  };

  handleToast.defaultProps = {
    actionPayload: {},
  };

  return {
    handleToast,
  };
};

export default useToast;
