// Libraries
import arrayMove from 'array-move';

const move = ({list, fromIndex, toIndex}) => {
  return arrayMove(list, fromIndex, toIndex);
};

const remove = (list, index) => {
  const front = list.slice(0, index);
  const back = list.slice(index + 1, list.length);
  return [...front, ...back];
};

const insertIf = (condition, ...elements) => {
  return condition ? elements : [];
};

const List = {
  move,
  remove,
  insertIf,
};

export default List;
