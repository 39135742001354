// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import UnitInputParser from './UnitInputParser';

const UnitInput = ({
  disabled,
  keyboardType,
  component,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  setFieldValue,
  setFieldTouched,
  style,
  options,
  unitString,
  ...props
}) => (
  <UnitInputParser
    {...props}
    name={name}
    disabled={disabled}
    keyboardType={keyboardType}
    placeholder={placeholder}
    value={value}
    component={component}
    onChangeText={(value) => setFieldValue(name, value)}
    onBlur={(event) => {
      onBlur(event);
      setFieldTouched(name, true);
    }}
    style={style}
    options={options}
    unitString={unitString}
  />
);

// --------------------------------------------------
// Props
// --------------------------------------------------
UnitInput.propTypes = {
  keyboardType: PropTypes.string,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  unitString: PropTypes.string.isRequired,
};

UnitInput.defaultProps = {
  // This type allows numbers and punctuation.
  keyboardType: 'phone-pad',
  onBlur: () => {},
};

export default UnitInput;
