// Libraries
import {useEffect, useState} from '@supermove/hooks';
import {BackgroundGeolocation} from '@supermove/sdk';

/**
 * Creates a listener that listens for location changes.
 */
const useBackgroundGeolocation = ({timeout, distanceFilter}) => {
  const [location, setLocation] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    BackgroundGeolocation.start({
      distanceFilter,
      timeout,
      onSuccess: (position) => {
        const {
          coords: {latitude, longitude, accuracy, altitude, floor, heading, speed},
        } = position;
        setLocation({latitude, longitude, accuracy, altitude, floor, heading, speed});
      },
      onError: (error) => {
        console.log(`[Location]: Error fetching: ${error}.`);
        setError(error);
      },
    });

    // Clean up and turn off the module.
    return () => BackgroundGeolocation.stop();
  }, [distanceFilter, timeout, setLocation, setError]);

  return {
    location,
    error,
  };
};

export default useBackgroundGeolocation;
