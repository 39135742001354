import gqlOriginal from 'graphql-tag';

// There's a very weird situation where
// 1) We are calling gql.errors and gql.query in several places, and these are not defined in the original ts definition.
// 2) If we do a simple reassignment in the index.ts file above, the unit tests that include a mock gql definition fail
// But for some reason, doing a reassignment here, adding a type annotation of "any" to ignore the .errors and .query calls we make works
// If anyone has an idea why the exact same code to reassign in a .js here is great but doesn't work in a .ts file, that would be great to know

/**
 * @type {any}
 */
const gql = gqlOriginal;

export default gql;
