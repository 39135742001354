/**
 * Modifiers are used in conjunction with styled-components functions. Ideally
 * these would work with react hooks but can't right now because styled-components
 * functions aren't evaluated in the render function which is a requirememt for hooks.
 */

// Libraries
import _ from 'lodash';

export const withResponsive = (styles) => {
  return ({responsive}) => {
    const size = _.find(['large', 'desktop', 'tablet', 'mobile'], (size) => {
      if (_.get(responsive, size) && _.get(styles, size)) {
        return size;
      }
    });

    const defaultStyle = _.get(styles, 'default');
    return _.get(styles, size, defaultStyle);
  };
};
