// Libraries
import React from 'react';

// Components
import Analytics from './Analytics';

const Track = ({children}) => (
  <Analytics.Consumer>{(analytics) => children(analytics.track)}</Analytics.Consumer>
);

export default Track;
