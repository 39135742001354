// Libraries
import {useFormik, FormikConfig, FormikValues} from 'formik';

// Equivalent to a FormikConfig type, except that its onSubmit parameter is optional
type UseFormOptions<Values> = Omit<FormikConfig<Values>, 'onSubmit'> &
  Partial<Pick<FormikConfig<Values>, 'onSubmit'>>;

const useForm = <Values extends FormikValues = FormikValues>(options: UseFormOptions<Values>) => {
  return useFormik({
    // HACK(mark): We use `form.submitForm` to trigger validation errors. We need
    // this in order to set all fields to `touched`. There isn't an easy way to do this
    // in formik.
    onSubmit: (values, formikHelpers) => {},
    // This improves speed of input rendering so components don't render multiple times see https://supermove.slack.com/archives/CKJS89NR3/p1669843277730259
    validateOnChange: false,
    ...options,
  });
};

export default useForm;
