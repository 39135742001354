// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {Permissions} from '@supermove/sdk';

class PermissionsView extends React.Component {
  state = {
    status: Permissions.RESULTS.UNDETERMINED,
  };

  componentDidMount() {
    this.mounted = true;
    this.updateStatus();
  }

  componentDidUpdate(previousProps) {
    this.updateStatus();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateStatus = async () => {
    const {type} = this.props;
    const status = await Permissions.check(type);

    if (this.mounted && status !== this.state.status) {
      this.setState({status});
    }
  };

  render() {
    const {status} = this.state;
    return this.props.children({
      status,
      isAuthorized: status === Permissions.RESULTS.AUTHORIZED,
      isDenied: status === Permissions.RESULTS.DENIED,
      isRestricted: status === Permissions.RESULTS.RESTRICTED,
      isUndetermined: status === Permissions.RESULTS.UNDETERMINED,
    });
  }
}

// --------------------------------------------------
// Props
// --------------------------------------------------
PermissionsView.propTypes = {
  type: PropTypes.string.isRequired,
};

PermissionsView.defaultProps = {};

export default PermissionsView;
