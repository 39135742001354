const US_EASTERN = 'US/Eastern';
const US_CENTRAL = 'US/Central';
const US_MOUNTAIN = 'US/Mountain';
const US_ARIZONA = 'US/Arizona';
const US_PACIFIC = 'US/Pacific';
const US_ALASKA = 'US/Alaska';
const US_HAWAII = 'US/Hawaii';

const getTimezoneUTCOffset = (timezone, isDST = false) => {
  const timezoneMap = {
    [US_EASTERN]: -5 * 60,
    [US_CENTRAL]: -6 * 60,
    [US_MOUNTAIN]: -7 * 60,
    [US_ARIZONA]: -7 * 60,
    [US_PACIFIC]: -8 * 60,
    [US_ALASKA]: -9 * 60,
    [US_HAWAII]: -10 * 60,
  };
  let offset = timezoneMap[timezone];
  if (isDST) {
    offset += 60;
  }
  return offset;
};

const VALUES = [US_EASTERN, US_CENTRAL, US_MOUNTAIN, US_ARIZONA, US_PACIFIC, US_ALASKA, US_HAWAII];

const getTimezoneDropdownOptions = () => {
  return VALUES.map((option) => ({
    label: option,
    value: option,
  }));
};

const Timezone = {
  US_EASTERN,
  US_CENTRAL,
  US_MOUNTAIN,
  US_ARIZONA,
  US_PACIFIC,
  US_ALASKA,
  US_HAWAII,

  getTimezoneDropdownOptions,
  getTimezoneUTCOffset,
};

export default Timezone;
