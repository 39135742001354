// Libraries
import FlatList from '@react-x/flat-list';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Document, Page} from 'react-pdf';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import './AnnotationLayer.css';

// Supermove
import {useState} from '@supermove/hooks';

// Components
import Styled from '../Styled';

const Container = Styled.View`
  flex: 1;
`;

const PdfView = ({source, onLoad}) => {
  const [pagesCount, setPagesCount] = useState(0);
  const pageNumbers = _.range(pagesCount);

  return (
    <Container>
      <Document
        file={source.uri}
        onLoadSuccess={(pdf) => {
          setPagesCount(pdf.numPages);
          onLoad({numberOfPages: pdf.numPages});
        }}
      >
        <FlatList
          initialNumToRender={1}
          data={pageNumbers}
          keyExtractor={(number, index) => String(index)}
          renderItem={({item, index}) => <Page pageNumber={index + 1} />}
        />
      </Document>
    </Container>
  );
};

// Standard A4 dimensions
const PAGE_HEIGHT = 841.89;
const PAGE_WIDTH = 595.28;
PdfView.PAGE_ASPECT_RATIO = PAGE_HEIGHT / PAGE_WIDTH;

// --------------------------------------------------
// Props
// --------------------------------------------------
PdfView.propTypes = {
  source: PropTypes.object.isRequired,
  onLoad: PropTypes.func,
};

PdfView.defaultProps = {
  onLoad: () => {},
};

export default PdfView;
