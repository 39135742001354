// Libraries
import _ from 'lodash';
import qs from 'qs';

// Given an object, create a url string with query params
const getUrlFromVariables = (baseRoute, variables = {}) => {
  // Keep URLs clean by removing falsy values from variables, includes boolean false
  // Otherwise qs will encode them
  // (ex. { a: "", b: "" } -> "?a=&b=")
  const varsWithoutEmptyValues = _.pickBy(
    variables,
    (value) => value !== undefined && value !== null && value !== '',
  );

  const variablesWithStringBooleans = _.reduce(
    varsWithoutEmptyValues,
    (result, value, key) => {
      if (_.isBoolean(value)) {
        // Convert boolean variables to string representation ("true" or "false")
        return {...result, [key]: value.toString()};
      }
      return {...result, [key]: value};
    },
    {},
  );

  // Create the query string
  const queryString = qs.stringify(variablesWithStringBooleans);

  return `${baseRoute}?${queryString}`;
};

const getLinkFromVariables = (baseRoute, variables) => {
  const {origin} = window.location;
  const url = getUrlFromVariables(baseRoute, variables);
  return `${origin}${url}`;
};

const URL = {
  getUrlFromVariables,
  getLinkFromVariables,
};

export default URL;
