// Libraries
import _ from 'lodash';

// Supermove
import {useRef, useState} from '@supermove/hooks';

const useToggle = ({name} = {}) => {
  const ref = useRef();
  const [isOn, setIsOn] = useState(false);
  const handleToggleOn = () => setIsOn(true);
  const handleToggleOff = () => setIsOn(false);
  const handleToggle = () => setIsOn(!isOn);

  return {
    key: `${_.startCase(name)}_${isOn}`,
    ref,
    isOn,
    handleToggleOn,
    handleToggleOff,
    handleToggle,
  };
};

export default useToggle;
