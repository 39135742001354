// Supermove
import {useAnalytics} from '@supermove/analytics';

// TODO(mark): pageName and url should come from a route context object so that we don't need
// to pass it in each time.
const useTrackEvent = ({eventName, eventType, pageName, url, additionalData = {}}) => {
  const analytics = useAnalytics();

  const trackEvent = () => {
    analytics.track({
      event: eventName,
      data: {
        'Type': eventType,
        'Page Name': pageName,
        'URL': url,
        ...additionalData,
      },
    });
  };

  const trackEventHandler = (handler) => {
    return (...args) => {
      trackEvent();
      return handler(...args);
    };
  };

  return {
    trackEvent,
    trackEventHandler,
  };
};

export default useTrackEvent;
