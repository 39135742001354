// Libraries
import {useCallback, useEffect, useState} from '@supermove/hooks';
import {BackgroundGeolocation} from '@supermove/sdk';

/**
 * Fetches the current location once whenever isEnabled changes to true. Provides
 * a handler to trigger a manual fetch.
 */
const useCurrentGeolocation = ({isEnabled, timeout}) => {
  const [location, setLocation] = useState();
  const [error, setError] = useState();

  const fetchLocation = useCallback(() => {
    BackgroundGeolocation.getCurrentPosition({
      timeout,
      onSuccess: (position) => {
        const {
          coords: {latitude, longitude, accuracy, altitude, floor, heading, speed},
        } = position;
        setLocation({latitude, longitude, accuracy, altitude, floor, heading, speed});
      },
      onError: (error) => {
        console.log(`[Location]: Error fetching: ${error.message}.`);
        setError(error);
      },
    });
  }, [timeout, setLocation, setError]);

  useEffect(() => {
    if (isEnabled) {
      fetchLocation();
    }
  }, [isEnabled, fetchLocation]);

  return {
    fetchLocation,
    location,
    error,
  };
};

export default useCurrentGeolocation;
