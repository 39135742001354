// Libraries
import ActivityIndicator from '@react-x/activity-indicator';
import PropTypes from 'prop-types';
import React from 'react';

// App
import {colors} from '@supermove/styles';

// Components
import Button from './Button';

const LoadingButton = ({disabled, loading, children, ...props}) => (
  <Button disabled={loading || disabled} {...props}>
    {children}
    {loading && (
      <ActivityIndicator
        size={'small'}
        color={colors.gray.border}
        style={{
          marginLeft: 5,
        }}
      />
    )}
  </Button>
);

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

LoadingButton.defaultProps = {
  loading: false,
  disabled: false,
};

export default LoadingButton;
