// Libraries
import React from 'react';
import ReactSidebar from 'react-sidebar';

const Sidebar = ({isOpen, position, onOpen, onClose, content, children, ...props}) => {
  return (
    <ReactSidebar
      open={isOpen}
      pullRight={position === 'right'}
      pullLeft={position === 'left'}
      sidebar={content}
      onSetOpen={(open) => (open ? onOpen() : onClose())}
      children={children}
      {...props}
    />
  );
};

export default Sidebar;
