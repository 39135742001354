// Supermove
import {useState, useRef} from '@supermove/hooks';

/**
 * This hook should be used with the DragAndDropList component.
 * It provides the stateful logic to disable a drag while
 * onReorder is in progress.
 */
const useDragAndDrop = () => {
  const [isReordering, setIsReordering] = useState(false);
  const handleReorderStart = () => setIsReordering(true);
  const handleReorderEnd = () => setIsReordering(false);

  const ref = useRef();
  const handleScrollTo = ({x, y}) => {
    ref.current.scrollTop = y;
    ref.current.scrollLeft = x;
  };
  const handleScrollToTop = () => {
    ref.current.scrollTop = 0;
  };
  const handleScrollToEnd = () => {
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  return {
    isReordering,
    handleReorderStart,
    handleReorderEnd,
    ref,
    handleScrollTo,
    handleScrollToTop,
    handleScrollToEnd,
  };
};

export default useDragAndDrop;
