// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {colors} from '@supermove/styles';

// Components
import Icon from '../Icon';
import Styled from '../Styled';

const Touchable = Styled.Touchable`
`;

const Container = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) =>
    props.disabled ? colors.gray.border : props.checked ? props.color : colors.transparent};
  border-color: ${(props) => (props.borderColor ? props.borderColor : props.color)};
  border-radius: 2px;
  border-width: 2px;
`;

const Checkbox = ({disabled, checked, size, iconSize, color, borderColor, onChange, style}) => {
  return (
    <Touchable
      disabled={disabled}
      activeOpacity={1}
      onPress={() => onChange(!checked)}
      style={style}
    >
      <Container
        checked={checked}
        disabled={disabled}
        size={size}
        color={color}
        borderColor={borderColor}
      >
        <Icon
          color={disabled ? colors.gray.border : colors.white}
          size={iconSize}
          source={Icon.Check}
        />
      </Container>
    </Touchable>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  size: PropTypes.number,
  iconSize: PropTypes.number,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

Checkbox.defaultProps = {
  disabled: false,
  color: colors.gray.secondary,
  size: 20,
  iconSize: 16,
  style: {},
};

export default Checkbox;
