// Libraries
import {GoogleApiWrapper} from 'google-maps-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

// Supermove
import {useHover, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Location} from '@supermove/utils';

// Components
import {ScrollView} from '..';
import Styled from '../Styled';

const Container = Styled.View`
  width: 100%;
`;

const Input = Styled.TextInput`
  ${Typography.Body3}
`;

const Wrapper = Styled.View`
`;

const Results = Styled.View`
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white};
  overflow: hidden;
`;

const Label = Styled.Text`
  ${Typography.Label}
  padding: 12px;
`;

const Result = Styled.Text`
  ${Typography.Body}
  padding: 12px;
  padding-left: ${({isSectioned}) => (isSectioned ? 24 : 12)}px;
`;

const ManualOptionButton = Styled.ButtonV2`
`;

const Placeholder = Styled.Text`
  ${Typography.Body}
  padding: 12px;
  padding-left: 24px;
  color: ${colors.gray.tertiary}
`;

const getLocationPayload = async ({address}) => {
  try {
    // Look up the result using the google geocoder.
    const geocode = await Location.getGeocode(address);
    const city = Location.getCity(geocode);
    const zipCode = Location.getZipCode(geocode);
    const state = Location.getState(geocode);
    const country = Location.getCountry(geocode);
    const {latitude, longitude} = await Location.getLatitudeLongitude(geocode);
    return {
      address,
      city,
      zipCode,
      latitude,
      longitude,
      state,
      country,
    };
  } catch (error) {
    return null;
  }
};

const getNativeProps = ({onKeyDown, ...props}) => ({
  ...props,
  onKeyPress: onKeyDown,
});

const handleSetLocation = async ({
  setFieldValue,
  setFieldError,
  setFieldTouched,
  onLocation,
  onSelect,
  name,
  address,
  isLocationLoadingField,
}) => {
  if (isLocationLoadingField) {
    setFieldValue(isLocationLoadingField, true);
  }
  setFieldValue(name, address);
  const locationPayload = await getLocationPayload({address});
  if (locationPayload) {
    // Clear the error before calling the onLocation callback.
    setFieldError(name, undefined);
    onLocation(locationPayload);
    onSelect(address);
  } else {
    setFieldTouched(name, true);
    setFieldError(name, 'Invalid location entered.');
  }
  if (isLocationLoadingField) {
    setFieldValue(isLocationLoadingField, false);
  }
};

const WarehouseOption = ({option, handlePressOption, getSuggestionItemProps}) => {
  const {ref, isHovered} = useHover();

  return (
    <ManualOptionButton
      ref={ref}
      {...getSuggestionItemProps(option)}
      onPress={() => handlePressOption(option)}
      style={{
        cursor: 'pointer',
        backgroundColor: isHovered ? colors.blue.accent : colors.white,
      }}
    >
      <Result style={{paddingLeft: 24}}>{option.description}</Result>
    </ManualOptionButton>
  );
};

const WarehouseOptions = ({label, options, handlePressOption, getSuggestionItemProps}) => {
  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      {!options.length && <Placeholder>No warehouses found</Placeholder>}
      {options.map((option, index) => {
        return (
          <WarehouseOption
            key={index}
            option={option}
            handlePressOption={handlePressOption}
            getSuggestionItemProps={getSuggestionItemProps}
          />
        );
      })}
    </React.Fragment>
  );
};
const ManualOption = ({option, handlePressOption, getSuggestionItemProps}) => {
  const {ref, isHovered} = useHover();

  return (
    <ManualOptionButton
      ref={ref}
      {...getSuggestionItemProps(option)}
      onPress={() => handlePressOption(option)}
      style={{
        cursor: 'pointer',
        backgroundColor: isHovered ? colors.blue.accent : colors.white,
      }}
    >
      <Result style={{paddingLeft: 24}}>{option.description}</Result>
    </ManualOptionButton>
  );
};

const ManualOptions = ({label, options, handlePressOption, getSuggestionItemProps}) => {
  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      {!options.length && <Placeholder>No locations found</Placeholder>}
      {options.map((option, index) => {
        return (
          <ManualOption
            key={index}
            option={option}
            handlePressOption={handlePressOption}
            getSuggestionItemProps={getSuggestionItemProps}
          />
        );
      })}
    </React.Fragment>
  );
};

const SuggestionOptions = ({
  suggestionsLabel,
  suggestionsPlaceholder,
  suggestions,
  getSuggestionItemProps,
  search,
}) => {
  return (
    <React.Fragment>
      {suggestionsLabel && <Label>{suggestionsLabel}</Label>}
      {search === '' && suggestionsPlaceholder && (
        <Placeholder>{suggestionsPlaceholder}</Placeholder>
      )}
      {search !== '' && !suggestions.length && <Placeholder>No locations found</Placeholder>}
      {suggestions.map((option, index) => {
        const className = option.active ? 'suggestion-item--active' : 'suggestion-item';
        const style = option.active
          ? {backgroundColor: colors.blue.accent, cursor: 'pointer'}
          : {backgroundColor: colors.white, cursor: 'pointer'};
        return (
          <div {...getSuggestionItemProps(option, {className, style})} key={index}>
            <Result style={suggestionsLabel && {paddingLeft: 24}}>{option.description}</Result>
          </div>
        );
      })}
    </React.Fragment>
  );
};

const getInitialLocation = ({initialLocation}) => {
  const {google} = window;
  if (!google || !initialLocation) {
    return {
      locationBias: 'IP_BIAS',
    };
  }
  // https://developers.google.com/maps/documentation/javascript/reference/places-service#LocationBias
  return {
    locationBias: {
      center: {
        lat: initialLocation.latitude,
        lng: initialLocation.longitude,
      },
      // Approximately 40 miles. Converts from meters to miles.
      radius: 40 * 1600,
    },
  };
};

const getSearchOptions = ({initialLocation}) => {
  return {
    ...getInitialLocation({initialLocation}),
  };
};

const LocationInput = ({
  disabled,
  required,
  autoComplete,
  name,
  placeholder,
  value,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  onSelect,
  onLocation,
  style,
  isLocationLoadingField,
  initialLocation,
  autoFocus,
  suggestionsLabel,
  suggestionsPlaceholder,
  manualOptionsLabel,
  manualOptions,
  warehouseOptionsLabel,
  warehouseOptions,
  handlePressWarehouseOption,
  handlePressManualOption,
  onChangeText,
}) => {
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const searchOptions = getSearchOptions({initialLocation});
  const showManualOptions = manualOptions && isFocused;
  const showWarehouseOptions = warehouseOptions && isFocused;
  const resetInput = () => {
    setSearch('');
    setIsFocused(false);
  };

  return (
    <PlacesAutocomplete
      value={value}
      onChange={(value) => {
        setIsFocused(true);
        setSearch(value);
        onChangeText(value);
        setFieldValue(name, value);
      }}
      onSelect={(value) => {
        handleSetLocation({
          setFieldValue,
          setFieldError,
          setFieldTouched,
          onLocation,
          onSelect,
          name,
          address: value,
          isLocationLoadingField,
        });
        resetInput();
      }}
      onError={(status, clearSuggestions) => clearSuggestions()}
      searchOptions={searchOptions}
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <Container>
          <Input
            {...getNativeProps(
              getInputProps({
                disabled,
                required,
                autoComplete,
                autoFocus,
                placeholder,
                style,
                onFocus: () => setIsFocused(true),
                onBlur: () => {
                  // We want the input to take the first suggestion if the user
                  // starts a search but then leaves the field before actually
                  // selecting anything. This allows the user to tab to the next
                  // field and automatically select the first suggestion.
                  const firstSuggestion = _.first(suggestions);
                  if (isFocused && firstSuggestion) {
                    handleSetLocation({
                      setFieldValue,
                      setFieldError,
                      setFieldTouched,
                      onLocation,
                      onSelect,
                      name,
                      address: firstSuggestion.description,
                      isLocationLoadingField,
                    });
                  }
                  resetInput();
                },
              }),
            )}
          />
          <Wrapper>
            {(showManualOptions || (isFocused && suggestions.length > 0)) && (
              <Results>
                <ScrollView style={{paddingVertical: 12, maxHeight: 320}}>
                  {showWarehouseOptions && (
                    <WarehouseOptions
                      label={warehouseOptionsLabel}
                      options={warehouseOptions}
                      getSuggestionItemProps={getSuggestionItemProps}
                      handlePressOption={(option) => {
                        handlePressWarehouseOption(option);
                        resetInput();
                      }}
                    />
                  )}
                  {showManualOptions && (
                    <ManualOptions
                      label={manualOptionsLabel}
                      options={manualOptions}
                      getSuggestionItemProps={getSuggestionItemProps}
                      handlePressOption={(option) => {
                        handlePressManualOption(option);
                        resetInput();
                      }}
                    />
                  )}
                  <SuggestionOptions
                    suggestionsLabel={suggestionsLabel}
                    suggestionsPlaceholder={suggestionsPlaceholder}
                    suggestions={suggestions}
                    getSuggestionItemProps={getSuggestionItemProps}
                    search={search}
                  />
                </ScrollView>
              </Results>
            )}
          </Wrapper>
        </Container>
      )}
    </PlacesAutocomplete>
  );
};

const getConfig = (props) => ({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
});

// --------------------------------------------------
// Props
// --------------------------------------------------
LocationInput.propTypes = {
  onSelect: PropTypes.func,
  suggestionsLabel: PropTypes.string,
  suggestionsPlaceholder: PropTypes.string,
  manualOptions: PropTypes.array,
  handlePressManualOption: PropTypes.func,
  onChangeText: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  initialLocation: PropTypes.object,
};

LocationInput.defaultProps = {
  onSelect: () => {},
  suggestionsLabel: null,
  suggestionsPlaceholder: null,
  manualOptions: null,
  handlePressManualOption: () => {},
  onChangeText: () => {},
  initialLocation: null,
};

export default GoogleApiWrapper(getConfig)(LocationInput);
