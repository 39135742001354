// Libraries
import ScrollView from '@react-x/scroll-view';
import React from 'react';

// Components
import Styled from '../../Styled';

const TabBarContainer = Styled.View`
`;

const ScrollViewContainer = Styled.View`
`;

const FullWidthBar = Styled.View`
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: ${({color}) => color};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const DisplayTabs = ({children, selectedIndex, handleChangeIndex, TabComponent}) => {
  return (
    <React.Fragment>
      {React.Children.map(children, ({props}, index) => {
        return (
          <TabComponent
            key={index}
            tab={props.tab}
            index={index}
            isSelected={index === selectedIndex}
            handlePress={() => handleChangeIndex(index)}
          />
        );
      })}
    </React.Fragment>
  );
};

const TabBar = ({
  children,
  selectedIndex,
  handleChangeIndex,
  TabComponent,
  scrollEnabled,
  showFullWidthBar,
  fullWidthBarColor,
  fullWidthBarStyle,
  style,
  showsHorizontalScrollIndicator,
  scrollViewRef,
}) => {
  return (
    <TabBarContainer style={style}>
      {scrollEnabled ? (
        <ScrollViewContainer>
          {showFullWidthBar && <FullWidthBar color={fullWidthBarColor} style={fullWidthBarStyle} />}
          <ScrollView
            ref={scrollViewRef}
            horizontal
            showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
          >
            <DisplayTabs
              children={children}
              selectedIndex={selectedIndex}
              handleChangeIndex={handleChangeIndex}
              TabComponent={TabComponent}
            />
          </ScrollView>
        </ScrollViewContainer>
      ) : (
        <Row>
          <DisplayTabs
            children={children}
            selectedIndex={selectedIndex}
            handleChangeIndex={handleChangeIndex}
            TabComponent={TabComponent}
          />
        </Row>
      )}
    </TabBarContainer>
  );
};

export default TabBar;
