import {useModal} from '@supermove/hooks';

const useDrawer = ({
  initialIsOpen = false,
  onOpen,
  onClose,
  openDelay = 0,
  name,
  enableTracking = true,
}) => {
  return useModal({
    initialIsOpen,
    onOpen,
    onClose,
    openDelay,
    name,
    enableTracking,
  });
};

export default useDrawer;
