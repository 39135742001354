// Libraries
import React from 'react';

// Supermove
import {Lifecycle} from '@supermove/components';

// Components
import Analytics from './Analytics';

const SkipIdentify = ({viewer, children}) => (
  <Analytics.Consumer>
    {(analytics) => <Lifecycle onMount={() => analytics.skipIdentify()} children={children} />}
  </Analytics.Consumer>
);

export default SkipIdentify;
