// Libraries
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClient} from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import {setContext} from 'apollo-link-context';
import {onError as onGraphQLError} from 'apollo-link-error';
import {RestLink} from 'apollo-link-rest';
import {RetryLink} from 'apollo-link-retry';
import {createUploadLink} from 'apollo-upload-client';
import merge from 'lodash.merge';

/**
 * GraphQL context object passed to Query components that need to use RestLink, instead of the /graphql API endpoint
 */
const RestContext = {
  useRest: true,
};

const restLink = new RestLink({
  uri: '', // Empty uri to silence console warnings - @rest directives should use the 'endpoint' field
  endpoints: {
    static: '/',
  },
});

const createErrorMiddleware = ({onError}) => {
  return onGraphQLError((options) => onError(options));
};

const createRetryMiddleware = ({delay, attempts}) => {
  return new RetryLink({delay, attempts});
};

const createGraphQLMiddleware = ({uri}) => {
  return ApolloLink.split(
    (operation) => operation.getContext().useRest,
    restLink,
    createUploadLink({uri}),
  );
};

const createHeadersMiddleware = ({getHeaders}) => {
  return setContext(async (request, context) => {
    const headers = await getHeaders();
    return {
      headers: {
        ...(context.headers || {}),
        ...request.headers,
        ...headers,
      },
    };
  });
};

const createAuthenticationMiddleware = ({getToken}) => {
  return createHeadersMiddleware({
    getHeaders: async () => {
      const token = await getToken();
      return {
        Authorization: token,
      };
    },
  });
};

const DEFAULT_OPTIONS = {
  watchQuery: {
    fetchPolicy: 'cache-first',
  },
};

const typePolicies = {
  CalendarDay: {
    merge: true,
  },
  DispatchCalendarDay: {
    merge: true,
  },
  EmployeeApprovalStatus: {
    merge: true,
  },
  Organization: {
    fields: {
      features: {
        // Short for options.mergeObjects(existing, incoming).
        merge: true,
      },
      block: {
        merge: true,
      },
    },
  },
};
const createCache = (options = {}) => {
  return new InMemoryCache({addTypename: true, ...options, typePolicies});
};

const createClient = (config = {}) => {
  const {cache, middleware = [], defaultOptions = {}, ...options} = config;

  return new ApolloClient({
    link: ApolloLink.from(middleware),
    cache,
    defaultOptions: merge({}, DEFAULT_OPTIONS, defaultOptions),
    ...options,
  });
};

export {
  // Client
  createCache,
  createClient,
  // Middleware
  createAuthenticationMiddleware,
  createErrorMiddleware,
  createGraphQLMiddleware,
  createHeadersMiddleware,
  createRetryMiddleware,
  RestContext,
};
