/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {useResponsive} from '@supermove/hooks';

// Components
import {Modal} from '..';
import Popover from '../Popover';
import Styled from '../Styled';

const ModalContainer = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ResponsivePopover = ({
  children,
  isOpen,
  handleClose,
  handleOpen,
  reference,
  isClosableOutside,
  placement,
  offset,
  arrow,
  onComponentDidHide,
}) => {
  const responsive = useResponsive();

  if (responsive.mobile) {
    return (
      <Modal.Content isOpen={isOpen} onClose={handleClose}>
        <ModalContainer>{children}</ModalContainer>
      </Modal.Content>
    );
  }
  return (
    <Popover
      handleOpen={handleOpen}
      handleClose={handleClose}
      reference={reference}
      isOpen={isOpen}
      children={children}
      isClosableOutside={isClosableOutside}
      placement={placement}
      offset={offset}
      arrow={arrow}
      onComponentDidHide={onComponentDidHide}
    />
  );
};

export default ResponsivePopover;
