// Libraries
import {Children} from '@increment/components';
import {GoogleApiWrapper, InfoWindow, Map, Marker, Polyline} from 'google-maps-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

// Assets
import './MapView.css';

const METERS_TO_MILES = 0.00062137;
const hasPoints = (values) => values.length >= 2;

class MapView extends React.Component {
  state = {
    route: [],
    distances: [],
    bounds: undefined,
  };

  // Google services are lazily initialized to only be called when needed.
  get directions() {
    if (!this._directions) {
      this._directions = new this.props.google.maps.DirectionsService();
    }
    return this._directions;
  }

  get panorama() {
    if (!this._panorama) {
      const node = ReactDOM.findDOMNode(this.refs.panorama);
      this._panorama = new this.props.google.maps.StreetViewPanorama(node);
    }
    return this._panorama;
  }

  get streetView() {
    if (!this._streetView) {
      this._streetView = new this.props.google.maps.StreetViewService();
    }
    return this._streetView;
  }

  componentDidMount() {
    // Only force bounds to be reset when the component is initially mounted
    this.update({forceBoundsToReset: true});
  }

  componentDidUpdate(previousProps) {
    this.update({previousProps});
  }

  update({previousProps = {}, forceBoundsToReset = false}) {
    this.updateStreetView(previousProps);
    this.updateDirections(previousProps);
    if (forceBoundsToReset || this.props.shouldResetBounds) {
      this.updateBounds(previousProps);
    }
  }

  updateStreetView({streetView: previousStreetView}) {
    const {streetView, google} = this.props;

    if (!streetView) {
      if (this._panorama) {
        this.panorama.setVisible(false);
      }
    } else if (streetView !== previousStreetView) {
      const source = google.maps.StreetViewSource.OUTDOOR;
      const params = {location: streetView, radius: 50, source};
      this.streetView.getPanorama(params, (data, status) => {
        if (data) {
          this.panorama.setPano(data.location.pano);
          this.panorama.setPov({
            heading: 0,
            pitch: 0,
          });
          this.panorama.setVisible(true);
        }
      });
    }
  }

  updateDirections({directions: previousDirections}) {
    const {directions} = this.props;
    const {route} = this.state;
    const isSameDirections = _.isEqual(directions, previousDirections);

    if (hasPoints(route) && !hasPoints(directions)) {
      // Reset the state and remove the routes, distances until we have at least 2 points.
      return this.updateRoute({route: [], distances: []});
    }

    if (directions.length === 1 && !isSameDirections) {
      // If there is only one location, still trigger an updateRoute so that we
      // correctly compute the distances to `[0]`.
      return this.updateRoute({route: [], distances: []});
    }

    if (hasPoints(directions) && !isSameDirections) {
      return this.fetchNewRoute({directions});
    }
  }

  makeWaypoints(points) {
    return points.map((point) => ({
      // Latitude / longitude pair of formatted points.
      location: point,

      // Splits the route into multiple routes, one for each stop.
      stopover: true,
    }));
  }

  makeBounds(points) {
    const bounds = new this.props.google.maps.LatLngBounds();
    points.forEach((point) => bounds.extend(point));
    return bounds;
  }

  getLegDistances(legs) {
    return legs.map((leg) => {
      const distanceInMeters = _.get(leg, 'distance.value', 0);
      const distanceInMiles = distanceInMeters * METERS_TO_MILES;
      return distanceInMiles;
    });
  }

  async fetchNewRoute({directions}) {
    const travelMode = this.props.google.maps.TravelMode.DRIVING;
    const [origin, ...rest] = directions;
    const waypoints = this.makeWaypoints(_.initial(rest));
    const destination = _.last(rest);
    const params = {origin, waypoints, destination, travelMode, optimizeWaypoints: false};
    const request = new Promise((resolve, reject) => {
      this.directions.route(params, (data, status) => {
        if (data && data.routes.length > 0) {
          resolve(data);
        } else {
          reject(new Error('Invalid route'));
        }
      });
    });

    try {
      const data = await request;
      const route = _.get(data, 'routes.0.overview_path', []).map((point) => ({
        lat: point.lat(),
        lng: point.lng(),
      }));

      // Calculate the distance for each leg of the trip.
      const legs = _.get(data, 'routes.0.legs', []);
      const distances = this.getLegDistances(legs);
      return this.updateRoute({route, distances});
    } catch (error) {
      console.log('Error fetching directions', error);
      const distances = _.range(directions.length - 1).map(() => 0);
      return this.updateRoute({route: [], distances});
    }
  }

  updateRoute({route, distances: routeDistances}) {
    // The first location always has 0 distance.
    const distances = [0, ...routeDistances];
    const bounds = this.makeBounds(_.concat(this.props.bounds, route));
    this.setState({route, distances, bounds}, () => {
      if (this.props.onRouteUpdate) {
        const totalDistance = distances.reduce((sum, distance) => sum + distance, 0);
        this.props.onRouteUpdate({route, distances, totalDistance});
      }
    });
  }

  updateBounds({bounds: previousBounds}) {
    const {bounds} = this.props;

    if (bounds.length > 0 && !_.isEqual(bounds, previousBounds)) {
      return this.setState({bounds: this.makeBounds(bounds)});
    }

    if (bounds.length === 0 && this.state.bounds && !this.state.bounds.isEmpty()) {
      // We previous had bounds, but no longer. Reset to an empty bounds object.
      return this.setState({bounds: this.makeBounds([])});
    }
  }

  render() {
    const {
      isInfoWindowVisible,
      isScrollEnabled,
      google,
      mapTypeControl,
      streetViewControl,
      infoWindowChild,
      infoWindowPosition,
      initialZoom,
      initialCenter,
      streetView,
      onClick,
      onInfoWindowClose,
      onInfoWindowOpen,
      onReady,
      children,
    } = this.props;

    const {route, bounds} = this.state;

    return (
      <React.Fragment>
        <div
          ref={'panorama'}
          style={{
            display: streetView ? 'flex' : 'none',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        />
        <Map
          bounds={bounds}
          initialCenter={initialCenter}
          google={google}
          mapTypeControl={mapTypeControl}
          maxZoom={20}
          scrollwheel={isScrollEnabled}
          streetViewControl={streetViewControl}
          visible={!streetView}
          zoom={initialZoom}
          onClick={onClick}
          onReady={onReady}
        >
          <Children>{(props) => children({...props, route})}</Children>
          <InfoWindow
            position={infoWindowPosition}
            visible={isInfoWindowVisible}
            onClose={onInfoWindowClose}
            onOpen={onInfoWindowOpen}
          >
            {infoWindowChild}
          </InfoWindow>
        </Map>
      </React.Fragment>
    );
  }
}

MapView.propTypes = {
  isInfoWindowVisible: PropTypes.bool,
  isScrollEnabled: PropTypes.bool,
  bounds: PropTypes.array,
  directions: PropTypes.array,
  infoWindowChild: PropTypes.node,
  infoWindowPosition: PropTypes.object,
  initialZoom: PropTypes.number,
  shouldResetBounds: PropTypes.bool,
  streetView: PropTypes.object,
  streetViewControl: PropTypes.bool,
  onClick: PropTypes.func,
  onInfoWindowClose: PropTypes.func,
  onInfoWindowOpen: PropTypes.func,
  onRouteUpdate: PropTypes.func,
};

MapView.defaultProps = {
  isInfoWindowVisible: false,
  isScrollEnabled: true,
  bounds: [],
  directions: [],
  infoWindowChild: <div />,
  infoWindowPosition: null,
  initialZoom: 14,
  shouldResetBounds: true,
  streetView: null,
  streetViewControl: false,
  onClick: () => {},
  onInfoWindowClose: () => {},
  onInfoWindowOpen: () => {},
  onRouteUpdate: () => {},
};

const getConfig = (props) => ({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  version: '3.49',
});

const WithGoogleAPI = GoogleApiWrapper(getConfig)(MapView);

const Wrapped = ({style, ...props}) => {
  if (!process.env.GATSBY_GOOGLE_MAPS_API_KEY) {
    return null;
  }

  return (
    <div data-test-id='map' id={'google-map-view'} style={style}>
      <WithGoogleAPI {...props} />
    </div>
  );
};

// Add static components.
Wrapped.Marker = Marker;
Wrapped.Polyline = Polyline;

export default Wrapped;
