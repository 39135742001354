// Libraries
import React from 'react';

// Supermove
import {Typography} from '@supermove/styles';

// Components
import Styled from '../Styled';

const Container = Styled.View`
  align-items: center;
  justify-content: center;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  border-width: 2px;
  border-color: ${(props) => props.color};
  border-radius: 3px;
`;

const Text = Styled.Text`
  ${Typography.Label2}
  color: ${(props) => props.color};
  text-transform: uppercase;
`;

const Badge = ({color, children, style, textStyle}) => (
  <Container color={color} style={style}>
    <Text color={color} style={textStyle}>
      {children}
    </Text>
  </Container>
);

export default Badge;
