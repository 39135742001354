// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import {useTable, useBlockLayout} from 'react-table';
import {FixedSizeList} from 'react-window';

// Supermove
import {Styled} from '@supermove/components';
import {useMemo, useCallback, useState, useEffect} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Function used to calculate the scrollbar width since different browsers can have different widths so the table still looks neat
const scrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute(
    'style',
    'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;',
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const TableComponent = Styled.View`
    border: 1px solid black;
    width: 100%;
    overflow-x: auto;
`;

const Cell = Styled.View`
  border-style: solid;
  border-right-color: black;
  border-right-width: 1px;
  border-bottom-color: black;
  border-bottom-width: 1px;
  padding: 5px;
  overflow: hidden;
  background-color: ${({shadeBackground}) => (shadeBackground ? colors.gray.border : 'none')};
  height: 100%;
`;

const EditText = Styled.TextInput`
  ${Typography.Body3}
  display: flex;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
  white-space: nowrap;
  width: 100%;
`;

const HeaderCell = Styled.View`
  background-color: ${colors.gray.border};
  padding: 5px;
  border-style: solid;
  border-right-color: black;
  border-right-width: 1px;
  border-bottom-color: black;
  border-bottom-width: 1px;
`;

const CellText = Styled.Text`
  ${Typography.Body3}
  display: flex;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;
`;

const TableHeaders = Styled.View`
`;

const HeaderRow = Styled.View`
  display: inline-block;
  width: ${({rowProps}) => rowProps.width};
`;

const TableBody = Styled.View`
`;

const Row = Styled.View`
  display: inline-block;
  height: ${({rowProps}) => rowProps.height};
  left: ${({rowProps}) => rowProps.left};
  position: ${({rowProps}) => rowProps.position};
  right: ${({rowProps}) => rowProps.right};
  top: ${({rowProps}) => rowProps.top};
  width: ${({rowProps}) => rowProps.width};
`;

const Table = ({
  columns,
  data,
  tableHeight,
  itemSize,
  columnWidth,
  hasHeaders,
  striped,
  isEditable,
  handleUpdateData,
}) => {
  const defaultColumn = useMemo(
    () => ({
      width: columnWidth,
      Cell: ({...props}) => {
        return isEditable ? EditableCell(props) : ReadOnlyCell(props);
      },
    }),
    [columnWidth, isEditable],
  );

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const {getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow} =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        handleUpdateData,
      },
      useBlockLayout,
    );

  const ReadOnlyCell = ({cell}) => {
    return <CellText>{cell.value}</CellText>;
  };

  const EditableCell = ({value: savedValue, row, column, handleUpdateData}) => {
    const [value, setValue] = useState(savedValue);

    const onChangeText = (e) => {
      setValue(e.target.value);
    };

    const onBlur = () => {
      handleUpdateData({row: row.index, column: column.id, value});
    };

    // Make sure value on table is updated if value is changed externally
    useEffect(() => {
      setValue(savedValue);
    }, [savedValue]);

    return <EditText value={value} onChange={onChangeText} onBlur={onBlur} />;
  };

  const RenderRow = useCallback(
    ({index, style}) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <Row rowProps={row.getRowProps({style}).style}>
          {row.cells.map((cell) => {
            return (
              <Cell {...cell.getCellProps()} shadeBackground={striped && row.index % 2 === 1}>
                {cell.render('Cell')}
              </Cell>
            );
          })}
        </Row>
      );
    },
    [prepareRow, rows, striped],
  );

  // Render the UI for your table
  return (
    <TableComponent
      {...getTableProps()}
      totalColumnsWidth={totalColumnsWidth}
      scrollBarSize={scrollBarSize}
    >
      {hasHeaders && (
        <TableHeaders>
          {headerGroups.map((headerGroup) => (
            <HeaderRow
              key={headerGroup.getHeaderGroupProps().key}
              rowProps={headerGroup.getHeaderGroupProps().style}
            >
              {headerGroup.headers.map((column) => (
                <HeaderCell {...column.getHeaderProps()}>
                  <CellText>{column.render('Header')}</CellText>
                </HeaderCell>
              ))}
            </HeaderRow>
          ))}
        </TableHeaders>
      )}
      <TableBody {...getTableBodyProps()}>
        <FixedSizeList
          height={tableHeight}
          itemCount={rows.length}
          itemSize={itemSize}
          width={totalColumnsWidth + scrollBarSize}
        >
          {RenderRow}
        </FixedSizeList>
      </TableBody>
    </TableComponent>
  );
};

const Spreadsheet = ({
  columns, // Columns must be memoized as defined here https://react-table.tanstack.com/docs/quick-start
  data, // Data must be memoized as defined here https://react-table.tanstack.com/docs/quick-start
  tableHeight, // Default height of table
  itemSize, // Default height of each row
  columnWidth, // Default width of each column, can be overridden by passing in width property into the columns object see https://react-table.tanstack.com/docs/api/useTable#column-options
  hasHeaders, // Boolean value to show fixed headers on table
  striped, // Boolean value to show alternating background colors for each row
  isEditable, // Boolean value to change table from editable inputs or read only
  handleUpdateData, // Function to update table values on blur of input if isEditable set to true. Function will receive the row index, column id, and new value {row, column, value}
}) => {
  return (
    <Table
      columns={columns}
      data={data}
      tableHeight={tableHeight}
      itemSize={itemSize}
      columnWidth={columnWidth}
      hasHeaders={hasHeaders}
      striped={striped}
      isEditable={isEditable}
      handleUpdateData={handleUpdateData}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
Spreadsheet.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  tableHeight: PropTypes.number,
  itemSize: PropTypes.number,
  columnWidth: PropTypes.number,
  hasHeaders: PropTypes.bool,
  striped: PropTypes.bool,
  isEditable: PropTypes.bool,
  handleUpdateData: PropTypes.func,
};

Spreadsheet.defaultProps = {
  tableHeight: 500,
  itemSize: 40,
  columnWidth: 100,
  hasHeaders: true,
  striped: false,
  isEditable: false,
  handleUpdateData: () => {},
};

export default Spreadsheet;
