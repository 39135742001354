// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {Environment} from '@supermove/sdk';

class Mixpanel extends React.Component {
  get isEnabled() {
    return !!this.context.mixpanel && Environment.isProduction();
  }

  handleIdentify = ({id, firstName, lastName, email, ...rest}) => {
    if (this.isEnabled && id) {
      this.context.mixpanel.identify(id);
      this.context.mixpanel.people.set({
        ...rest,
        ID: id,
        $first_name: firstName,
        $last_name: lastName,
        $email: email,
      });
    }
  };

  handleTrack = ({event, data}) => {
    if (this.isEnabled) {
      this.context.mixpanel.track(event, data);
    }
  };

  handleReset = () => {
    if (this.isEnabled) {
      this.context.mixpanel.reset();
    }
  };

  render() {
    return this.props.children({
      identify: this.handleIdentify,
      track: this.handleTrack,
      reset: this.handleReset,
    });
  }
}

Mixpanel.contextTypes = {
  // This comes from the gatsby-plugin-mixpanel library and is already configured
  // with the mixpanel token.
  mixpanel: PropTypes.object,
};

export default Mixpanel;
