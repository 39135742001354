// Supermove
import {useEffect, useMountEffect, useState} from '@supermove/hooks';

// Modules
import NetInfo from './NetInfo';

const useInternet = () => {
  // Have we ever fetched internet state before?
  const [hasFetched, setHasFetched] = useState(false);

  // Have we ever been connected?
  const [hasConnected, setHasConnected] = useState(false);

  // Are we connected right now?
  const [isConnected, setIsConnected] = useState(false);

  const initialize = async () => {
    const isConnected = await NetInfo.isConnected.fetch();
    setIsConnected(isConnected);
    setHasConnected(isConnected);
    setHasFetched(true);
  };

  useMountEffect(() => {
    initialize();
  });

  useEffect(() => {
    const handleConnectionChange = (isConnected) => {
      setHasConnected(hasConnected || isConnected);
      setIsConnected(isConnected);
    };

    NetInfo.isConnected.addEventListener('connectionChange', handleConnectionChange);

    return () => {
      NetInfo.isConnected.removeEventListener('connectionChange', handleConnectionChange);
    };
  }, [hasConnected, hasFetched]);

  return {
    hasFetched,
    isConnected,
    hasConnected,
  };
};

export default useInternet;
