import {ExecutionEnvironment} from '@supermove/sdk';

const useTouch = () => {
  // canUseDOM check is a workaround to avoid Gatsby errors when it attempts
  // SSR builds(https://www.gatsbyjs.com/docs/debugging-html-builds/)

  // useTouch is not dynamic. When testing, it will require a refresh to
  // reinitialize the JavaScript environment and update the canUseDom value.
  const isTouch =
    ExecutionEnvironment.canUseDOM &&
    ('ontouchstart' in window ||
      window.navigator.maxTouchPoints > 0 ||
      window.navigator.msMaxTouchPoints > 0);

  return {isTouch};
};

export default useTouch;
