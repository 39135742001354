// Libraries
import PropTypes from 'prop-types';
import React from 'react';

class UXCam extends React.Component {
  get isEnabled() {
    return !!this.props.apiKey;
  }

  componentDidMount() {
    if (this.isEnabled) {
      // TODO
    }
  }

  handleIdentify = ({id, firstName, lastName, email, ...rest}) => {
    if (this.isEnabled && id) {
      // TODO
    }
  };

  handleTrack = ({event, data}) => {
    if (this.isEnabled) {
      // TODO
    }
  };

  handleReset = () => {
    if (this.isEnabled) {
      // TODO
    }
  };

  render() {
    return this.props.children({
      identify: this.handleIdentify,
      track: this.handleTrack,
      reset: this.handleReset,
    });
  }
}

UXCam.propTypes = {
  apiKey: PropTypes.string,
};

UXCam.defaultProps = {
  apiKey: null,
};

export default UXCam;
