// Libraries
import _ from 'lodash';

// Supermove
import {useForm, useFormMutation} from '@supermove/hooks';

const useInlineFormMutation = ({
  name,
  form: formData,
  toForm,
  toMutation,
  mutation,
  onSuccess = () => {},
  onError = () => {},
}: {
  name: string;
  form: any;
  toForm?: (data: any) => any;
  toMutation?: (data: any) => any;
  mutation: any;
  onSuccess?: (results: any) => void;
  onError?: (errors: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      [name]: toForm ? toForm(formData) : formData,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation,
    variables: {
      [name]: toMutation ? toMutation(form.values[name]) : form.values[name],
    },
    onSuccess,
    onError: (errors: any) => {
      console.log({errors});
      onError(errors);
    },
  });

  return {form, submitting, handleSubmit};
};

export default useInlineFormMutation;
