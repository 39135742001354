// Libraries
import React from 'react';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';

const ZoomView = ({
  defaultScale,
  defaultPositionX,
  defaultPositionY,
  minScale,
  centerContent,
  wrapperClass,
  children,
}) => {
  return (
    <TransformWrapper
      defaultScale={defaultScale}
      defaultPositionX={defaultPositionX}
      defaultPositionY={defaultPositionY}
      options={{minScale, centerContent, limitToWrapper: true, wrapperClass}}
      wheel={{wheelEnabled: false}}
    >
      <TransformComponent>{children}</TransformComponent>
    </TransformWrapper>
  );
};

export default ZoomView;
