// Libraries
import _ from 'lodash';
import qs from 'qs';
import {useHistory, useLocation, useParams} from 'react-router-dom';

const getSearchParams = ({location}) => {
  const search = _.replace(location.search, '?', '');
  return qs.parse(search);
};

const useNavigation = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  return {
    navigator: {
      ...history,
      pushNewTab: (url) => window.open(url),
    },
    params: {
      ...getSearchParams({location}),
      ...params,
    },
  };
};

export default useNavigation;
