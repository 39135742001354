// Libraries
import currency from 'currency.js';
import PropTypes from 'prop-types';
import React from 'react';

class CurrencyPicker extends React.Component {
  getMatches = (string, regex) => {
    return string.match(regex) || [];
  };

  formatValue = (value) => {
    if (value === '') {
      return '';
    }

    // Allow `-` for negative prices and allow `$` for matching.
    const numerics = this.getMatches(value, /[-]?[$]?[.]?\d+[.\d+]?/g).join('');
    const alpha = this.getMatches(value, /[a-zA-Z]+/g)
      .join('')
      .toLowerCase();

    if (alpha.length > 0) {
      return '';
    }

    const formatted = currency(numerics).format(true);
    if (this.props.shouldHideCentsIfZero) {
      return formatted.replace(/\.00$/, '');
    } else {
      return formatted;
    }
  };

  handleBlur = (event, options) => {
    this.props.onChangeText(this.formatValue(this.props.value, options));
  };

  render() {
    const {
      shouldHideCentsIfZero,
      component: InputComponent = 'input',
      value,
      onChangeText,
      options,
      onBlur,
      ...props
    } = this.props;

    return (
      <InputComponent
        {...props}
        value={value}
        onChangeText={onChangeText}
        onBlur={(event) => {
          onBlur(event);
          this.handleBlur(event, options);
        }}
        options={options}
      />
    );
  }
}

// --------------------------------------------------
// Props
// --------------------------------------------------
CurrencyPicker.propTypes = {
  shouldHideCentsIfZero: PropTypes.bool,
  options: PropTypes.object,
  onBlur: PropTypes.func,
};

CurrencyPicker.defaultProps = {
  shouldHideCentsIfZero: false,
  options: {},
  onBlur: () => {},
};

export default CurrencyPicker;
