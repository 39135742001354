// Libraries
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

class SignatureInput extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  // Imperative 'save' method to match the native component.
  save = () => {
    const signature = this.input.current;
    const data = signature.isEmpty() ? '' : signature.toDataURL();
    this.props.onSave(data);
  };

  clear = () => {
    this.input.current.clear();
  };

  render() {
    // TODO (warren): Consolidate this prop with some equivalent on native.
    const {color, onEnd, isEnabledAutoSave} = this.props;

    return (
      <SignatureCanvas
        ref={this.input}
        penColor={color}
        canvasProps={{
          style: {
            display: 'flex',
            flex: 1,
            width: '100%',
          },
        }}
        onEnd={() => {
          if (isEnabledAutoSave) {
            this.save();
          }
          if (onEnd) {
            onEnd();
          }
        }}
      />
    );
  }
}

export default SignatureInput;
