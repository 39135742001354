// Libraries
import {StackRouter, SwitchView, createNavigator} from '@react-navigation/core';
import React from 'react';

// Wraps the current scene with `wrapper` which is defined in the config.
// This allows us to have a wrapper component that can listen to navigation
// changes and log analytics.
const createNavigationView = (Wrapper) => (props) => (
  <Wrapper {...props}>
    {/* We intentionally use SwitchView (no transitions) over StackView. */}
    <SwitchView {...props} />
  </Wrapper>
);

const createStackWrapperNavigator = (routes, config) => {
  const {wrapper, ...stackConfig} = config;
  const NavigationView = createNavigationView(wrapper);
  const Router = StackRouter(routes, stackConfig);
  return createNavigator(NavigationView, Router, {});
};

export default createStackWrapperNavigator;
