// Libraries
import React from 'react';

import {ExecutionEnvironment} from '@supermove/sdk';

// Assets
import 'react-quill/dist/quill.snow.css';
import './RichTextInput.css';

const FONT_SIZES = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px',
];

const getModules = ({hasCustomToolbar}) => {
  if (hasCustomToolbar) {
    return {
      toolbar: {
        container: [
          [{header: [1, 2, false]}],
          [{size: FONT_SIZES}],
          ['bold', 'italic', 'underline', 'strike'],
          ['link'],
          [{align: []}, {list: 'ordered'}, {list: 'bullet'}],
          [{color: []}, {background: []}],
          ['clean'],
        ],
      },
      // React Quill has a known issue where extra <p><br></p> tags are added to white space on save.
      // Disabling matchVisual solves the issue, blocking the extra tags from being added.
      clipboard: {
        matchVisual: false,
      },
    };
  }

  return {};
};

const RichTextInput = ({
  disabled,
  name,
  placeholder,
  value,
  setFieldValue,
  style,
  hasCustomToolbar,
  shouldUseDivsForNewLines,
}) => {
  if (!ExecutionEnvironment.canUseDOM) {
    return null;
  }

  const ReactQuill = require('react-quill');
  const {Quill} = ReactQuill;

  const Size = Quill.import('attributors/style/size');
  Size.whitelist = FONT_SIZES;
  Quill.register(Size, true);

  // By default, Quill uses P tags for block elements. This changes it to use DIV tags.
  // This fixes discrepencies between how email servers and the editor render block elements.
  if (shouldUseDivsForNewLines) {
    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
  }

  // By default, Quill does not santize links to include a protocol.
  // This custom sanitizer adds a protocol if the user does not include one.
  // ie. "www.google.com" -> "//www.google.com"
  const Link = Quill.import('formats/link');
  const builtInFunc = Link.sanitize;
  Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
    let val = linkValueInput;

    // NOTE(jholston): As part of the rollout, we'll only sanitize links that have a custom toolbar
    // STYLING NOTE(shopking): If making a change here that adds functionality to the toolbar, the .ql-container class in RichTextInput.css
    //                         may need to be updated to accommodate subtracting more pixels from the height of the input container
    if (hasCustomToolbar) {
      // do not santize if there's a custom protocol (eg. mailto: or tel:) or if it contains http or https
      if (/^\w+:/.test(val));
      else if (!/^https?:/.test(val))
        if (!/^\/\//.test(val)) {
          // add protocol if it's missing
          val = `//${val}`;
        }
    }

    return builtInFunc.call(this, val); // retain the built-in sanitize logic
  };

  return (
    <ReactQuill
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={(value) => setFieldValue(name, value)}
      style={style}
      // TODO(jholston): Update all instances of RichTextInput to use the custom toolbar
      modules={getModules({hasCustomToolbar})}
    />
  );
};

export default RichTextInput;
