const Permissions = {
  LOCATION: 'location',

  RESULTS: {
    AUTHORIZED: 'authorized',
    DENIED: 'denied',
    RESTRICTED: 'restricted',
    UNDETERMINED: 'undetermined',
  },

  check: async (type) => {
    // TODO(mark): Implement for web.
    return Promise.resolve(Permissions.RESULTS.AUTHORIZED);
  },

  request: async (type) => {
    // TODO(mark): Implement for web.
    return Promise.resolve(Permissions.RESULTS.AUTHORIZED);
  },
};

export default Permissions;
