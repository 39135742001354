// Libraries
import React from 'react';

export interface AnalyticsContext {
  identify: ({
    id,
    firstName,
    lastName,
    email,
    Organization,
    Role,
  }: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    Organization: string;
    Role: string;
  }) => void;
  skipIdentify: () => void;
  track: ({event, data}: {event: string; data: Record<string, any>}) => void;
  reset: () => void;
  setGlobalEventData: ({
    name,
    version,
    buildNumber,
  }: {
    name: string;
    version: string;
    buildNumber: number;
  }) => void;
}

const AnalyticsContext = React.createContext<AnalyticsContext>({
  identify: () => console.error('Must provide a `identify` function to the Analytics.Provider.'),
  skipIdentify: () =>
    console.error('Must provide a `skipIdentify` function to the Analytics.Provider.'),
  track: () => console.error('Must provide a `track` function to the Analytics.Provider.'),
  reset: () => console.error('Must provide a `reset` function to the Analytics.Provider.'),
  setGlobalEventData: () =>
    console.error('Must provide a `setGlobalEventData` function to the Analytics.Provider.'),
});

export default AnalyticsContext;
