// Libraries
import React from 'react';
import ReactPhoneInput from 'react-phone-number-input/basic-input';

// Supermove
import {createStyledDOMInput} from '@supermove/styles';

const StyledPhoneInput = createStyledDOMInput(ReactPhoneInput);

const PhoneInput = ({
  disabled,
  required,
  name,
  placeholder,
  value,
  setFieldValue,
  autoFocus,
  style,
}) => (
  <StyledPhoneInput
    country={'US'}
    disabled={disabled}
    required={required}
    placeholder={placeholder}
    value={value}
    onChange={(value) => setFieldValue(name, value)}
    style={style}
    autoFocus={autoFocus}
  />
);

export default PhoneInput;
