const Collection = {
  mapWith: (array, mapper) => {
    return array.map((item, index) =>
      mapper(item, index, {
        isFirst: index === 0,
        isLast: index === array.length - 1,
      }),
    );
  },
};

export default Collection;
