// Libraries
import _ from 'lodash';

// Supermove
import {useTrackEvent} from '@supermove/hooks';

const getEventType = (name) => {
  // Since modals and sidebars are technically the same, we determine here whether
  // this event pertains to a modal or a sidebar
  if (name.endsWith('Sidebar')) {
    return 'Sidebar View';
  }

  return 'Modal View';
};

const useTrackModal = ({name}) => {
  const modalName = _.startCase(name);
  const eventType = getEventType(modalName);

  const {trackEvent} = useTrackEvent({
    eventName: `Viewed ${modalName}`,
    eventType,
    // TODO(sam): Add pageName when it is available in navigator
    pageName: '',
  });

  return trackEvent;
};

export default useTrackModal;
