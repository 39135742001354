// Supermove
import {useCallback} from '@supermove/hooks';
import {Storage, Config} from '@supermove/sdk';
import {Json} from '@supermove/utils';

/**
 * In some cases it is necessary to send a mutation just before the browser destroys the page.
 * Unfortunately, browsers don't make this easy and some of them intentionally cancel any in-flight
 * requests that were initiated before page teardown. See https://web.dev/disallow-synchronous-xhr/
 *
 * This hook solves this case with the keepalive flag. To use on page refresh or on page close, you
 * can use this hook in conjuction with useBeforeRefreshEffect()
 */
const useSubmitMutationWithKeepAlive = ({mutation, variables}) => {
  return useCallback(
    async ({onSuccess}) => {
      const token = await Storage.getItem('token');
      fetch(`${Config.getAPIHost()}/graphql`, {
        method: 'POST',
        // The keepalive option keeps this request alive even after the page has been torn down
        keepalive: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: Json.stringify({
          query: mutation.loc.source.body,
          variables,
        }),
      }).then((response) => onSuccess(response));
    },
    [mutation, variables],
  );
};

export default useSubmitMutationWithKeepAlive;
