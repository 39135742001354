// Libraries
import _ from 'lodash';

import {pluralize} from '@supermove/utils';

const Distance = {
  display: (distance) => {
    return pluralize('mile', _.round(distance, 1), true);
  },

  shortDisplay: (distance) => {
    return `${_.round(distance, 1)} mi`;
  },
};

export default Distance;
