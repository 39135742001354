// Libraries
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';

const updateBlobWithFile = (blob, file) => {
  blob.path = file.path;
  blob.lastModified = file.lastModified;
  blob.lastModifiedDate = file.lastModifiedDate;
  blob.name = file.name;
  blob.webkitRelativePath = file.webkitRelativePath;
  return blob;
};

// We create a helper here to handle cases where mimetype conversion are invalid
const getMimetypeForExtension = (extension) => {
  switch (extension) {
    case 'xlsb':
      return 'application/vnd.ms-excel.sheet.binary.macroenabled.12';
    default:
      return '';
  }
};

const FileDragInput = ({
  disabled,
  onFilesChange,
  accept,
  children,
  webOptions,
  disableDrag,
  disableClick,
  style,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = [];
      acceptedFiles.forEach((file) => {
        // https://github.com/react-dropzone/react-dropzone/issues/276/
        // There are certain cross platform issues with certain file and mime types
        // In order to fix the issue with cross browser/system inconsistencies,
        // We would need to handle cases that may break manually (IE: xlsb files on windows)
        if (file.type === '') {
          // Get file extension from file name
          const splitName = file.name.split('.');
          const extension = splitName[splitName.length - 1];

          // Create a blob to instantiate a new file
          const blob = new Blob([file], {type: getMimetypeForExtension(extension)});

          newFiles.push(updateBlobWithFile(blob, file));
        } else {
          newFiles.push(file);
        }
      });
      return onFilesChange(newFiles);
    },
    [onFilesChange],
  );
  const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
    onDrop,
    ...webOptions,
    noDrag: disableDrag,

    // This is a bug fix for react-dropzone. Right now, mobile web fails to
    // accept any files beyond the first one even if noClick is set to false.
    // Removing the prop entirely allows mobile to continue accepting files.
    ...(disableClick ? {noClick: true} : {}),
  });

  // The dropzone hook is incompatible with the Styled library, so we use a native div
  // to handle file drag logic.
  return (
    <div style={style} {...getRootProps()}>
      <input disabled={disabled} {...getInputProps()} accept={accept} />
      {children({isDragActive, handleOpen: open})}
    </div>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
FileDragInput.propTypes = {
  onFilesChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  children: PropTypes.func.isRequired,
  dropzoneOptions: PropTypes.object,
  style: PropTypes.object,
  disableDrag: PropTypes.bool,
  disableClick: PropTypes.bool,
};

FileDragInput.defaultProps = {
  accept: null,
  dropzoneOptions: {},
  style: null,
  disableDrag: false,
  disableClick: false,
};

export default FileDragInput;
