/* eslint-disable react-hooks/exhaustive-deps */

// Libraries
import React from 'react';

// Supermove
import {useEffect, useRef, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Icon from '../Icon';
import Space from '../Space';
import Styled from '../Styled';

const ICON_COLOR = colors.blue.interactive;
const PLAY_PAUSE_ICON_SIZE = 40;
const FORWARD_BACKWARD_ICON_SIZE = 20;
const AUDIO_SPEEDS = [1, 1.5, 2];

const Container = Styled.View`
  align-items: center;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const ButtonContainer = Styled.ButtonV2`
`;

const formatTime = ({seconds}) => {
  return new Date(seconds * 1000).toISOString().slice(14, 19);
};

const IconButton = ({icon, size, color, onPress}) => {
  return (
    <ButtonContainer onPress={onPress}>
      <Icon source={icon} size={size} color={color} />
    </ButtonContainer>
  );
};

const AudioSpeedButton = ({audioSpeedIndex, onPress, style}) => {
  const responsive = useResponsive();
  return (
    <ButtonContainer onPress={onPress}>
      <LabelText
        responsive={responsive}
        style={{
          color: colors.blue.interactive,
          ...style,
        }}
      >
        {`${AUDIO_SPEEDS[audioSpeedIndex]}x`}
      </LabelText>
    </ButtonContainer>
  );
};

const Slider = ({sliderValue, audioDuration, onSliderChange}) => {
  return (
    <input
      type={'range'}
      step={1}
      min={0}
      max={audioDuration}
      value={sliderValue}
      onChange={(event) => onSliderChange({newValue: event.target.value})}
      style={{alignSelf: 'stretch'}}
    />
  );
};

const Controls = ({isPlaying, setIsPlaying, skipForward, skipBackward}) => {
  const responsive = useResponsive();
  return (
    <Row>
      <MicroLabelText responsive={responsive}>15s</MicroLabelText>
      <Space width={8} />
      <IconButton
        icon={Icon.ArrowRotateLeft}
        size={FORWARD_BACKWARD_ICON_SIZE}
        color={ICON_COLOR}
        onPress={skipBackward}
      />
      <Space width={24} />
      {isPlaying ? (
        <IconButton
          icon={Icon.CirclePause}
          size={PLAY_PAUSE_ICON_SIZE}
          color={ICON_COLOR}
          onPress={() => setIsPlaying(false)}
        />
      ) : (
        <IconButton
          icon={Icon.CirclePlay}
          size={PLAY_PAUSE_ICON_SIZE}
          color={ICON_COLOR}
          onPress={() => setIsPlaying(true)}
        />
      )}
      <Space width={24} />
      <IconButton
        icon={Icon.ArrowRotateRight}
        size={FORWARD_BACKWARD_ICON_SIZE}
        color={ICON_COLOR}
        onPress={skipForward}
      />
      <Space width={8} />
      <MicroLabelText responsive={responsive}>15s</MicroLabelText>
    </Row>
  );
};

const AudioPlayer = React.forwardRef(
  ({audioSrc, onCurrentTimeChange, isPlaying, setIsPlaying}, audioRef) => {
    const responsive = useResponsive();
    const [sliderValue, setSliderValue] = useState(0);
    const [audioDuration, setAudioDuration] = useState(0);
    const [audioSpeedIndex, setAudioSpeedIndex] = useState(0);
    const intervalRef = useRef();

    // Save duration of audio in state when audio is loaded.
    const onAudioLoadedMetadata = () => {
      setAudioDuration(audioRef.current.duration);
    };

    // Update position of audio and slider when slider is used.
    const onSliderChange = ({newValue}) => {
      if (!isPlaying) {
        setIsPlaying(true);
      }

      audioRef.current.currentTime = newValue;
      setSliderValue(newValue);
    };

    const skipForward = () => {
      audioRef.current.currentTime += 15;
      setSliderValue(audioRef.current.currentTime);
    };

    const skipBackward = () => {
      audioRef.current.currentTime -= 15;
      setSliderValue(audioRef.current.currentTime);
    };

    const changeAudioSpeed = () => {
      let newAudioSpeedIndex;
      if (audioSpeedIndex === AUDIO_SPEEDS.length - 1) {
        newAudioSpeedIndex = 0;
      } else {
        newAudioSpeedIndex = audioSpeedIndex + 1;
      }

      setAudioSpeedIndex(newAudioSpeedIndex);
      audioRef.current.playbackRate = AUDIO_SPEEDS[newAudioSpeedIndex];
    };

    // Update slider position when audio is playing.
    useEffect(() => {
      if (isPlaying) {
        audioRef.current.play();

        // Clear any timers already running.
        clearInterval(intervalRef.current);

        // Update every 100ms to avoid time increasing right after music is paused.
        intervalRef.current = setInterval(() => {
          setSliderValue(audioRef.current.currentTime);
        }, [100]);
      } else {
        audioRef.current.pause();
      }
    }, [isPlaying]);

    // Clean up on unmount.
    useEffect(() => {
      const audioRefCurrent = audioRef.current;
      return () => {
        audioRefCurrent.pause();
        clearInterval(intervalRef.current);
      };
    }, []);

    useEffect(() => {
      // Expose changes in currentTime to parent component.
      if (audioRef.current && onCurrentTimeChange) {
        onCurrentTimeChange({currentTime: audioRef.current.currentTime});
      }

      // Set the player to paused after playback has finished
      if (audioRef.current && audioRef.current.currentTime === audioRef.current.duration) {
        setIsPlaying(false);
      }
    }, [audioRef.current?.currentTime]);

    /* eslint-disable jsx-a11y/media-has-caption */
    return (
      <React.Fragment>
        <audio src={audioSrc} ref={audioRef} onLoadedMetadata={onAudioLoadedMetadata} />
        <Container>
          <Slider
            sliderValue={sliderValue}
            audioDuration={audioDuration}
            onSliderChange={onSliderChange}
          />
          <Space height={8} />
          <MicroText responsive={responsive}>
            {`${formatTime({seconds: sliderValue})} / ${formatTime({seconds: audioDuration})}`}
          </MicroText>
          <Space height={16} />
          <Row style={{alignSelf: 'stretch', justifyContent: 'space-between'}}>
            <AudioSpeedButton audioSpeedIndex={audioSpeedIndex} onPress={changeAudioSpeed} />
            <Controls
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              skipForward={skipForward}
              skipBackward={skipBackward}
            />
            {/* Use hidden component instead of space to avoid having to hard code space width. */}
            <AudioSpeedButton audioSpeedIndex={audioSpeedIndex} style={{visibility: 'hidden'}} />
          </Row>
        </Container>
      </React.Fragment>
    );
  },
);

export default AudioPlayer;
