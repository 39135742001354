// Libraries
import _ from 'lodash';
import numeral from 'numeral';

const getMatches = (string, regex) => {
  return string.match(regex) || [];
};

const Percent = {
  display: (float) => {
    return Percent.toString(float);
  },
  fromFraction: ({numerator, denominator, round = 2}) => {
    return Percent.display(_.round(numerator / denominator, round));
  },
  toFloat: (percent) => {
    const integer = parseFloat(percent) || 0;
    return integer / 100;
  },
  toString: (float) => {
    return numeral(float).format('0[.][000]%');
  },

  toForm: (float) => {
    if (float === null || float === undefined || float === '') {
      return '';
    }
    return Percent.display(float);
  },
  toMutation: (percent) => {
    return Percent.toFloat(percent);
  },

  // formatValue function taken from PercentInputParser
  formatValue: (value) => {
    // Allow `-, %, .` for negative, percents, and decimals.
    const numerics = getMatches(value, /[-]?\d+[.\d+]?[%]?/g).join('');
    const alpha = getMatches(value, /[a-zA-Z]+/g)
      .join('')
      .toLowerCase();

    if (alpha.length > 0) {
      return '';
    }

    const float = parseFloat(numerics) || 0;
    return `${float}%`;
  },
};

export default Percent;
