// Libraries
import _ from 'lodash';

const Float = {
  // Used for Form objects.
  toForm: (value) => Float.toString(value),
  toMutation: (value) => Float.toFloat(value),

  display: (value) => value,
  toFloat: (value, defaultValue = 0) => parseFloat(value) || defaultValue,
  toPercentage: (value) => _.round(value / 100),
  toString: (float) => (float ? String(float) : ''),
  toOrdinal: (float) => {
    const int = Math.round(float);
    if (int > 3 && int < 21) return `${int}th`;
    switch (int % 10) {
      case 1:
        return `${int}st`;
      case 2:
        return `${int}nd`;
      case 3:
        return `${int}rd`;
      default:
        return `${int}th`;
    }
  },
  toLowercaseLetter: (float) => String.fromCharCode(97 + float),
};

export default Float;
