// Supermove
import {useTrackEvent} from '@supermove/hooks';

const getEventType = (name) => {
  return 'Popover View';
};

const useTrackPopover = ({name}) => {
  const eventType = getEventType(name);

  const {trackEvent} = useTrackEvent({
    eventName: `Viewed ${name}`,
    eventType,
    // TODO(sam): Add pageName when it is available in navigator
    pageName: '',
  });

  return trackEvent;
};

export default useTrackPopover;
