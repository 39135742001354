// Libraries
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCopy as farCopy, faTrashAlt as farTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBars,
  faBell,
  faBolt,
  faBox,
  faBoxes,
  faBoxOpen,
  faBullhorn,
  faCalculator,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faClipboardList,
  faClock,
  faCode,
  faCog,
  faCommentAlt,
  faCommentDots,
  faCompress,
  faCreditCard,
  faDollarSign,
  faDoorClosed,
  faDoorOpen,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileDownload,
  faFileInvoice,
  faFilter,
  faFlagCheckered,
  faFolder,
  faGhost,
  faGraduationCap,
  faGripVertical,
  faHandHoldingUsd,
  faImage,
  faInfoCircle,
  faLightbulb,
  faListUl,
  faLock,
  faLockOpen,
  faMapMarkedAlt,
  faMapMarker,
  faMapPin,
  faMinus,
  faPen,
  faPencilAlt,
  faPhone,
  faPhoneAlt,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRoad,
  faSearch,
  faSearchPlus,
  faSignOutAlt,
  faSlidersH,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faSpinner,
  faStar,
  faStickyNote,
  faStopCircle,
  faSync,
  faTag,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTrophy,
  faTruck,
  faUnlink,
  faUnlock,
  faUpload,
  faUser,
  faUserEdit,
  faUserPlus,
  faUsers,
  faVideo,
  faVideoSlash,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import {
  faArrowsFromLine,
  faArrowsToLine,
  faDownLeftAndUpRightToCenter,
  faStar as farStar,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeftToLine,
  faArrowRightToLine,
  faArrowRotateLeft,
  faArrowRotateRight,
  faArrowUpRightFromSquare,
  faBoxArchive,
  faBriefcaseBlank,
  faBuilding,
  faBuildingCircleArrowRight,
  faCalendarClock,
  faCalendarExclamation,
  faCalendarXmark,
  faChartLineUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleInfo,
  faCirclePause,
  faCirclePlay,
  faCirclePlus,
  faCircleXmark,
  faClockRotateLeft,
  faComment,
  faCommentPen,
  faComments,
  faCommentSms,
  faCopy,
  faDesktop,
  faEraser,
  faFileCheck,
  faFileLines,
  faFilePlus,
  faGear,
  faGrid2,
  faHourglassClock,
  faHouseBuilding,
  faHouseChimney,
  faLightbulbOn,
  faLink,
  faLinkSlash,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMoneyCheckDollarPen,
  faObjectsAlignCenterHorizontal,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faPaperclip,
  faPaperPlaneTop,
  faPenField,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faPhoneHangup,
  faPhoneXmark,
  faRobot,
  faRocketLaunch,
  faRotate,
  faRulerTriangle,
  faShare,
  faStarExclamation,
  faTabletScreenButton,
  faUserCheck,
  faUserClock,
  faUserGroup,
  faWeightHanging,
  faXmark,
  faXmarkLarge,
} from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Platform} from '@supermove/sdk';

// Components
import Styled from '../Styled';

import FontAwesomeIcon from './FontAwesomeIcon';
import Image from './Image';

library.add(
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowLeftToLine,
  faArrowRight,
  faArrowRightToLine,
  faArrowRotateLeft,
  faArrowRotateRight,
  faArrowsFromLine,
  faArrowsToLine,
  faArrowUp,
  faArrowUpRightFromSquare,
  faBan,
  faBars,
  faBell,
  faBolt,
  faBox,
  faBoxArchive,
  faBoxes,
  faBoxOpen,
  faBriefcaseBlank,
  faBuilding,
  faBuildingCircleArrowRight,
  faBullhorn,
  faCalculator,
  faCalendar,
  faCalendarCheck,
  faCalendarClock,
  faCalendarDay,
  faCalendarExclamation,
  faCalendarXmark,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faChartLineUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faCirclePause,
  faCirclePlay,
  faCirclePlus,
  faCircleXmark,
  faClipboardList,
  faClock,
  faClockRotateLeft,
  faCode,
  faCog,
  faComment,
  faCommentAlt,
  faCommentDots,
  faCommentPen,
  faComments,
  faCommentSms,
  faCompress,
  faCopy,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDoorClosed,
  faDoorOpen,
  faDownLeftAndUpRightToCenter,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEraser,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileCheck,
  faFileDownload,
  faFileInvoice,
  faFileLines,
  faFilePlus,
  faFilter,
  faFlagCheckered,
  faFolder,
  faGear,
  faGhost,
  faGraduationCap,
  faGrid2,
  faGripVertical,
  faHandHoldingUsd,
  faHourglassClock,
  faHouseBuilding,
  faHouseChimney,
  faImage,
  faInfoCircle,
  faLightbulb,
  faLightbulbOn,
  faLink,
  faLinkSlash,
  faListUl,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMapMarkedAlt,
  faMapMarker,
  faMapPin,
  faMinus,
  faMoneyCheckDollarPen,
  faObjectsAlignCenterHorizontal,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faPaperclip,
  faPaperPlaneTop,
  faPen,
  faPencilAlt,
  faPenField,
  faPhone,
  faPhoneAlt,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faPhoneHangup,
  faPhoneXmark,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  farCopy,
  faRoad,
  faRobot,
  faRocketLaunch,
  faRotate,
  farStar,
  farTrashAlt,
  faRulerTriangle,
  faSearch,
  faSearchPlus,
  faShare,
  faSignOutAlt,
  faSlidersH,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faSpinner,
  faStar,
  faStarExclamation,
  faStickyNote,
  faStopCircle,
  faSync,
  faTabletScreenButton,
  faTag,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTrophy,
  faTruck,
  faUnlink,
  faUnlock,
  faUpload,
  faUser,
  faUserCheck,
  faUserClock,
  faUserEdit,
  faUserGroup,
  faUserPlus,
  faUsers,
  faVideo,
  faVideoSlash,
  faWeightHanging,
  faWrench,
  faXmark,
  faXmarkLarge,
);

const InternalImageComponentStyleWrapper = Styled.View`
`;

const Icon = ({color, size, source, style}) => {
  const InternalImageComponent = Image[source];
  if (InternalImageComponent) {
    if (style) {
      return (
        <InternalImageComponentStyleWrapper style={style}>
          <InternalImageComponent color={color} size={size} />
        </InternalImageComponentStyleWrapper>
      );
    }
    return <InternalImageComponent color={color} size={size} />;
  }

  return (
    <FontAwesomeIcon
      color={color}
      icon={source}
      size={Platform.select({
        web: '1x',
        default: size,
      })}
      style={Platform.select({
        web: {
          ...style,
          fontSize: size,
        },
        default: style,
      })}
      // The fa-fw class is used to set a fixed width on all icons for a given
      // fontSize, which is set by our size prop.
      className={'fa-fw'}
    />
  );
};

Icon.AngleDown = 'angle-down';
Icon.AngleLeft = 'angle-left';
Icon.AngleRight = 'angle-right';
Icon.AngleUp = 'angle-up';
Icon.Archive = 'archive';
Icon.ArrowDown = 'arrow-down';
Icon.ArrowLeft = 'arrow-left';
Icon.ArrowLeftToLine = 'arrow-left-to-line';
Icon.ArrowRight = 'arrow-right';
Icon.ArrowRightToLine = 'arrow-right-to-line';
Icon.ArrowRotateLeft = ['fas', 'arrow-rotate-left'];
Icon.ArrowRotateRight = ['fas', 'arrow-rotate-right'];
Icon.ArrowsFromLine = ['far', 'arrows-from-line'];
Icon.ArrowsToLine = ['far', 'arrows-to-line'];
Icon.ArrowUp = 'arrow-up';
Icon.ArrowUpRightFromSquare = 'arrow-up-right-from-square';
Icon.Ban = 'ban';
Icon.Bars = 'bars';
Icon.Bell = 'bell';
Icon.Bolt = 'bolt';
Icon.Box = 'box';
Icon.BoxArchive = ['fas', 'box-archive'];
Icon.Boxes = 'boxes';
Icon.BoxOpen = 'box-open';
Icon.BriefCaseBlank = 'briefcase-blank';
Icon.Building = 'building';
Icon.BuildingCircleArrowRight = ['fas', 'building-circle-arrow-right'];
Icon.Bullhorn = 'bullhorn';
Icon.Calculator = 'calculator';
Icon.Calendar = 'calendar';
Icon.CalendarCheck = 'calendar-check';
Icon.CalendarClock = 'calendar-clock';
Icon.CalendarDay = 'calendar-day';
Icon.CalendarExclamation = 'calendar-exclamation';
Icon.CalendarXmark = 'calendar-xmark';
Icon.Camera = 'camera';
Icon.CaretDown = 'caret-down';
Icon.CaretUp = 'caret-up';
Icon.ChartLine = 'chart-line';
Icon.ChartLineUp = 'chart-line-up';
Icon.Check = 'check';
Icon.CheckCircle = 'check-circle';
Icon.CheckSquare = 'check-square';
Icon.ChevronDown = 'chevron-down';
Icon.ChevronLeft = 'chevron-left';
Icon.ChevronRight = 'chevron-right';
Icon.ChevronUp = 'chevron-up';
Icon.Circle = 'circle';
Icon.CircleCheck = 'circle-check';
Icon.CircleInfo = 'circle-info';
Icon.CirclePause = 'circle-pause';
Icon.CirclePlay = 'circle-play';
Icon.CirclePlus = 'circle-plus';
Icon.CircleXmark = 'circle-xmark';
Icon.ClipboardList = 'clipboard-list';
Icon.Clock = 'clock';
Icon.ClockRotateLeft = ['fas', 'clock-rotate-left'];
Icon.Code = 'code';
Icon.Cog = 'cog';
Icon.Comment = 'comment';
Icon.CommentAlt = 'comment-alt';
Icon.CommentDots = 'comment-dots';
Icon.CommentPen = ['fas', 'comment-pen'];
Icon.Comments = 'comments';
Icon.CommentSms = ['fas', 'comment-sms'];
Icon.Compress = 'compress';
Icon.Copy = 'copy';
Icon.CopyRegular = ['far', 'copy'];
Icon.CreditCard = 'credit-card';
Icon.Desktop = 'desktop';
Icon.DollarSign = 'dollar-sign';
Icon.DoorClosed = 'door-closed';
Icon.DoorOpen = 'door-open';
Icon.DownLeftAndUpRightToCenter = ['far', 'down-left-and-up-right-to-center'];
Icon.Edit = 'edit';
Icon.EllipsisH = 'ellipsis-h';
Icon.EllipsisV = 'ellipsis-v';
Icon.Envelope = 'envelope';
Icon.Eraser = 'eraser';
Icon.ExclamationCircle = 'exclamation-circle';
Icon.ExclamationTriangle = 'exclamation-triangle';
Icon.Expand = 'expand';
Icon.ExternalLinkAlt = 'external-link-alt';
Icon.Eye = 'eye';
Icon.EyeSlash = 'eye-slash';
Icon.File = 'file';
Icon.FileAlt = 'file-alt';
Icon.FileCheck = ['fas', 'file-check'];
Icon.FileDownload = 'file-download';
Icon.FileInvoice = 'file-invoice';
Icon.FileLines = 'file-lines';
Icon.FilePlus = ['fas', 'file-plus'];
Icon.Filter = 'filter';
Icon.FlagCheckered = 'flag-checkered';
Icon.Folder = 'folder';
Icon.Gear = ['fas', 'gear'];
Icon.Ghost = 'ghost';
Icon.GraduationCap = 'graduation-cap';
Icon.Grid2 = 'grid-2';
Icon.GripVertical = 'grip-vertical';
Icon.HandHoldingUsd = 'hand-holding-usd';
Icon.HourglassClock = 'hourglass-clock';
Icon.HouseBuilding = 'house-building';
Icon.HouseChimney = 'house-chimney';
Icon.Image = 'image';
Icon.InfoCircle = 'info-circle';
Icon.Lightbulb = 'lightbulb';
Icon.LightbulbOn = 'lightbulb-on';
Icon.Link = 'link';
Icon.LinkSlash = ['fas', 'link-slash'];
Icon.ListUl = 'list-ul';
Icon.Lock = 'lock';
Icon.LockOpen = 'lock-open';
Icon.MagnifyingGlass = 'magnifying-glass';
Icon.MagnifyingGlassPlus = 'magnifying-glass-plus';
Icon.MapMarkedAlt = 'map-marked-alt';
Icon.MapMarker = 'map-marker';
Icon.MapPin = 'map-pin';
Icon.Minus = 'minus';
Icon.MoneyCheckDollarPen = 'money-check-dollar-pen';
Icon.ObjectsAlignCenter = 'objects-align-center-horizontal';
Icon.ObjectsAlignLeft = 'objects-align-left';
Icon.ObjectsAlignRight = 'objects-align-right';
Icon.Paperclip = ['fas', 'paperclip'];
Icon.PaperPlaneTop = 'paper-plane-top';
Icon.Pen = 'pen';
Icon.Pencil = 'pencil-alt';
Icon.PenField = 'pen-field';
Icon.Phone = 'phone';
Icon.PhoneAlt = 'phone-alt';
Icon.PhoneHangup = 'phone-hangup';
Icon.PhoneIncoming = 'phone-arrow-down-left';
Icon.PhoneMissed = 'phone-xmark';
Icon.PhoneOutgoing = 'phone-arrow-up-right';
Icon.Plus = 'plus';
Icon.Print = 'print';
Icon.Question = 'question';
Icon.QuestionCircle = 'question-circle';
Icon.Road = 'road';
Icon.Robot = 'robot';
Icon.RocketLauch = 'rocket-launch';
Icon.Rotate = ['fas', 'rotate'];
Icon.RulerTriangle = 'ruler-triangle';
Icon.Search = 'search';
Icon.SearchPlus = 'search-plus';
Icon.Share = 'share';
Icon.SignOut = 'sign-out-alt';
Icon.Sliders = 'sliders-h';
Icon.SortAmountDownAlt = 'sort-amount-down-alt';
Icon.SortAmountUpAlt = 'sort-amount-up-alt';
Icon.Spinner = 'spinner';
Icon.Star = 'star';
Icon.StarExclamation = 'star-exclamation';
Icon.StarOutline = ['far', 'star'];
Icon.StickyNote = 'sticky-note';
Icon.StopCircle = 'stop-circle';
Icon.Sync = 'sync';
Icon.TabletScreenButton = 'tablet-screen-button';
Icon.Tag = 'tag';
Icon.Times = 'times';
Icon.TimesCircle = 'times-circle';
Icon.Trash = 'trash';
Icon.TrashAlt = 'trash-alt';
Icon.TrashAltRegular = ['far', 'trash-alt'];
Icon.Trophy = 'trophy';
Icon.Truck = 'truck';
Icon.Unlink = 'unlink';
Icon.Unlock = 'unlock';
Icon.Upload = 'upload';
Icon.User = 'user';
Icon.UserCheck = 'user-check';
Icon.UserClock = 'user-clock';
Icon.UserEdit = 'user-edit';
Icon.UserGroup = 'user-group';
Icon.UserPlus = 'user-plus';
Icon.Users = 'users';
Icon.Video = 'video';
Icon.VideoSlash = 'video-slash';
Icon.WeightHanging = 'weight-hanging';
Icon.Wrench = 'wrench';
Icon.Xmark = ['fas', 'xmark'];
Icon.XmarkLarge = ['fas', 'xmark-large'];

// Internal image icons
Icon.GoogleMaps = 'GoogleMaps';
Icon.StreetView = 'StreetView';
Icon.Zillow = 'Zillow';

Icon.Sizes = {
  ExtraSmall: 10,
  Small: 12,
  Medium: 14,
  Large: 18,
  ExtraLarge: 25,
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
Icon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  style: PropTypes.object,
};

Icon.defaultProps = {
  color: null,
  size: 16,
  style: {},
};

Icon.SourcePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]);

export default Icon;
