// Libraries
import _ from 'lodash';
import React from 'react';
import {Controlled as CodeMirror} from 'react-codemirror2';

// Supermove
import './CodeEditor.css';
import {useState} from '@supermove/hooks';

// Import only if window object is available to avoid Gatsby SSR issues
// This package can only be used in the browser
if (typeof window !== 'undefined') {
  require('codemirror/lib/codemirror.css');
  require('codemirror/theme/material.css');
  require('codemirror/mode/htmlmixed/htmlmixed');
}

const CodeEditor = ({form, field}) => {
  const [code, setCode] = useState(_.get(form.values, field));

  // See comment above about SSR issues
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <div
      id={'revertGlobalStyles'}
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        overflowX: 'auto',
        backgroundColor: '#fff',
      }}
    >
      <CodeMirror
        value={code}
        options={{
          lineNumbers: true,
          mode: 'htmlmixed',
          theme: 'default',
          viewportMargin: Infinity,
          lineWrapping: true,
        }}
        onBeforeChange={(editor, data, value) => {
          setCode(value);
        }}
        onChange={(editor, data, value) => {
          form.setFieldValue(field, value);
        }}
        editorDidMount={(editor) => {
          editor.setSize(1210, '100%');
        }}
      />
    </div>
  );
};

export default CodeEditor;
