// Config
import EnvironmentVariables from './EnvironmentVariables';

const Environment = {
  get: (key) => {
    return EnvironmentVariables.get(key);
  },

  getAppEnv: () => {
    return Environment.get('APP_ENV') || 'development';
  },

  getAppName: () => {
    return Environment.get('APP_NAME') || 'Unknown';
  },

  getCommitHash: () => {
    return Environment.get('RENDER_GIT_COMMIT') || '';
  },

  getTracesSampleRate: () => {
    return Number(Environment.get('SENTRY_TRACES_SAMPLE_RATE'));
  },

  isLocal: () => {
    // 'gatsby develop' sets NODE_ENV to 'development' by default, and 'gatsby build' will always
    // set this to 'production'. See:
    // * https://github.com/gatsbyjs/gatsby/blob/gatsby%402.13.41/packages/gatsby-cli/src/create-cli.js#L152-L188
    // * https://github.com/gatsbyjs/gatsby/discussions/31802
    // For native builds that don't use gatsby, NODE_ENV is set via the .env.development file.
    // TODO(atsu): Clean up .env files for native files, so that the meaning of "development" and
    //   "production" is clearer. Need to make a distinction between a "development build" versus
    //   "making requests against the dev backend".
    return Environment.get('NODE_ENV') === 'development';
  },

  isProduction: () => {
    return Environment.getAppEnv() === 'production';
  },

  getEnvironmentName: () => {
    if (Environment.isLocal()) {
      return 'Local';
    }
    switch (Environment.getAppEnv()) {
      case 'production':
        return 'Production';
      case 'staging':
        return 'Staging';
      default:
        return 'Development';
    }
  },
  getAppUrl: () => {
    if (Environment.isLocal()) {
      // Don't provide an override for the AppUrl, let it use the default
      // When the app is generated via puppeteer in the PDF service it uses a different URL,
      // Hardcoding to http://localhost:9000 causes the service to fail
      return undefined;
    }
    switch (Environment.getAppEnv()) {
      case 'production':
        return 'https://app.supermove.co';
      case 'staging':
        return 'https://app.staging.supermove.co';
      default:
        return 'https://app.development.supermove.co';
    }
  },
};

export default Environment;
