// Libraries
import React from 'react';

import {Linking} from '@supermove/sdk';

// Components
import Styled from '../Styled';

const Touchable = Styled.ButtonV2`
`;

const ExternalLink = ({url, children, style}) => (
  <Touchable onPress={() => Linking.openURL(url)} style={style}>
    {children}
  </Touchable>
);

export default ExternalLink;
