// Libraries
import _ from 'lodash';
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';

import Retry from './Retry';

const Location = {
  // We memoize this function to prevent re-rendering a component that has
  // the same latitude and longitude.
  create: _.memoize(
    ({latitude, longitude}) => {
      if (!latitude || !longitude) {
        return null;
      }
      // Returns the format that Google Maps expects.
      return {lat: latitude, lng: longitude};
    },
    // Specifies a key to use for the memoize cache.
    ({latitude, longitude}) => `${latitude}-${longitude}`,
  ),

  createGoogleMapsUrl: (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  },

  createGoogleMapsRouteUrl: ({origin, destination, waypoints = []}) => {
    const encodedOrigin = encodeURIComponent(origin);
    const encodedDestination = encodeURIComponent(destination);
    // If waypoints are empty, no need to include the parameter
    const waypointsParam = _.isEmpty(waypoints)
      ? ''
      : `&waypoints=${waypoints.map((waypoint) => encodeURIComponent(waypoint)).join('|')}`;

    return `https://www.google.com/maps/dir/?api=1&origin=${encodedOrigin}&destination=${encodedDestination}${waypointsParam}`;
  },

  // Getters
  getGeocode: async (address) => {
    const results = await Retry.withTimeout(() => geocodeByAddress(address), 2000);
    return results[0];
  },
  getAddresses: (geocode, addressType) => {
    return _.filter(geocode.address_components, (address) => {
      return _.some(address.types, (type) => type === addressType);
    });
  },
  getFirstAddressLongName: (geocode, addressType) => {
    const addresses = Location.getAddresses(geocode, addressType);
    return addresses.length === 0 ? '' : _.first(addresses).long_name;
  },
  getCity: (geocode) => {
    // NOTE(mark): There are some addresses that do NOT have a 'locality'. For those,
    // we fallback to the 'neighborhood' and then 'political'.
    return (
      Location.getFirstAddressLongName(geocode, 'locality') ||
      Location.getFirstAddressLongName(geocode, 'neighborhood') ||
      Location.getFirstAddressLongName(geocode, 'political')
    );
  },
  getLatitudeLongitude: async (geocode) => {
    const location = await Retry.withTimeout(() => getLatLng(geocode), 2000);
    return {
      latitude: location.lat,
      longitude: location.lng,
    };
  },
  getZipCode: (geocode) => {
    return Location.getFirstAddressLongName(geocode, 'postal_code');
  },
  getState: (geocode) => {
    const state = Location.getAddresses(geocode, 'administrative_area_level_1');
    return state.length === 0 ? '' : _.first(state).short_name;
  },
  getCountry: (geocode) => {
    const country = Location.getAddresses(geocode, 'country');
    return country.length === 0 ? '' : _.first(country).short_name;
  },
};

export default Location;
