// Libraries
import * as Sentry from '@sentry/react';
import {Replay} from '@sentry/replay';
import {Integrations} from '@sentry/tracing';
import _ from 'lodash';

const ErrorHandler = {
  replay: null,

  initialize: ({
    environment,
    sentryUrl,
    ignoreErrors,
    tracesSampleRate,
    release,
    enableSessionReplay,
  }) => {
    const integrations = [new Integrations.BrowserTracing()];
    if (enableSessionReplay && !ErrorHandler.replay) {
      ErrorHandler.replay = new Replay({
        // See docs for options: https://github.com/getsentry/sentry-javascript/tree/master/packages/replay#configuration
        // Only collect replays when errors occur - TODO(atsu): Move to not-deprecated options after full rollout
        sessionSampleRate: 0.0,
        errorSampleRate: 1.0,
        // Set to 'false' to render text & images in replays
        maskAllText: false,
        blockAllMedia: false,
      });
      integrations.push(ErrorHandler.replay);
    }

    Sentry.init({
      dsn: sentryUrl,
      integrations,
      environment,
      ignoreErrors,
      denyUrls: [
        // Ignore errors from PayEngine web components
        /console\.payengine\.co/i,
        /console\.payengine\.dev/i,
        /js.verygoodvault.com/i,
      ],
      tracesSampleRate,
      release,
    });

    ErrorHandler.setTags({environment});
  },

  setUser: (user) => {
    Sentry.setUser(user);

    // Turn off replays for myguys, since some of their users have issues with Chrome memory usage...
    // TODO(atsu): Check overhead of session replays here https://github.com/getsentry/sentry-javascript/issues/6503
    if (ErrorHandler.replay && user?.Organization?.match(/myguysmoving|onebigman/g)) {
      console.log(`Stopping Session Replay for user: `, user);
      ErrorHandler.replay.stop();
    }
  },

  setExtra: (extra) => {
    _.forEach(extra, (value, key) => Sentry.setExtra(key, value));
  },

  setTags: (tags) => {
    _.forEach(tags, (value, key) => Sentry.setTag(key, value));
  },

  handleError: (error) => {
    Sentry.captureException(error);
  },

  handleMessage: (message, options) => {
    console.error(message);
    Sentry.captureMessage(message, options);
  },

  lastEventId: () => {
    return Sentry.lastEventId();
  },

  jsCrash: () => {
    throw new Error('[Test] JS Error');
  },

  nativeCrash: () => {
    throw new Error('[Test] Native Error');
  },
};

export default ErrorHandler;
