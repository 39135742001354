// Libraries
import {useEffect, useState} from 'react';

const useBrainfishWidget = ({apiKey, isVisible, onOpenChat}) => {
  const [brainfish, setBrainfish] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'brainfish-script';
    script.type = 'module';
    script.src = `https://cdn.jsdelivr.net/npm/@brainfish-ai/widgets-initiator@1.0.2/dist/web.js`;
    script.async = true;
    script.onload = async () => {
      // Initialize the Brainfish module.
      await window.Brainfish.Widgets.init({
        widgetKey: apiKey,
        widgetMode: 'slide-over',
        language: 'en',
        class: '',
        defaultOpen: false,
        overrides: {
          nextBestActions: [
            {
              type: 'callback',
              label: 'Chat support',
              value: ({query, answer}) => {
                // Close the widget before opening the chat widget.
                // TODO(mark): Can't use the brainfish in state due to circular loops.
                window.Brainfish.HelpWidget.close();

                // Open chat widget and prepopulate with question asked.
                onOpenChat({query});
              },
            },
          ],
        },
      });

      // Store in state so we can access outside the module.
      setBrainfish(window.Brainfish);
    };

    // Handle adding and removing the script file from the webpage.
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [apiKey, onOpenChat]);

  return {
    handleOpen: () => {
      if (brainfish) {
        brainfish.HelpWidget.open();
      }
    },
  };
};

export default useBrainfishWidget;
