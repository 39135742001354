// Libraries
import {persistCache} from 'apollo3-cache-persist';
import {useEffect, useState} from 'react';

const usePersistCache = ({cache, storage, maxSize, debug}) => {
  const [isPersisted, setIsPersisted] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      console.log('[Apollo] Loading persisted cache...');
      await persistCache({cache, storage, debug, maxSize});
      console.log('[Apollo] Loaded persisted cache.');
      setIsPersisted(true);
    };

    initialize();
  }, [cache, storage, maxSize, debug]);

  return {
    isPersisted,
  };
};

export default usePersistCache;
