const Storage = {
  getItem: async (key) => {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return null;
    }
  },
  setItem: async (key, value) => {
    try {
      return localStorage.setItem(key, value);
    } catch (error) {
      return null;
    }
  },
  removeItem: async (key) => {
    try {
      return localStorage.removeItem(key);
    } catch (error) {
      return null;
    }
  },
  clear: async () => {
    try {
      return localStorage.clear();
    } catch (error) {
      return null;
    }
  },
};

export default Storage;
