// Supermove
import {useState} from '@supermove/hooks';

const useTabs = ({initialIndex, onChangeIndex, numberOfTabs = 0}) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const hasNext = selectedIndex < numberOfTabs - 1;
  const hasPrev = selectedIndex > 0;

  const handleChangeIndex = (index) => {
    setSelectedIndex(index);
    if (onChangeIndex) {
      onChangeIndex(index);
    }
  };

  const handleNext = () => {
    if (hasNext) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handlePrev = () => {
    if (hasPrev) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  return {
    selectedIndex,
    setSelectedIndex,
    handleChangeIndex,
    handleNext,
    handlePrev,
    hasNext,
    hasPrev,
  };
};

export default useTabs;
