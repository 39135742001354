// Libraries
import PropTypes from 'prop-types';
import React from 'react';

class PercentInputParser extends React.Component {
  getMatches = (string, regex) => {
    return string.match(regex) || [];
  };

  formatValue = (value) => {
    if (value === '') {
      return '';
    }

    // Allow `-, %, .` for negative, percents, and decimals.
    const numerics = this.getMatches(value, /[-]?\d+[.\d+]?[%]?/g).join('');
    const alpha = this.getMatches(value, /[a-zA-Z]+/g)
      .join('')
      .toLowerCase();

    if (alpha.length > 0) {
      return '';
    }

    const float = parseFloat(numerics) || 0;

    return `${float}%`;
  };

  handleBlur = (event, options) => {
    this.props.onChangeText(this.formatValue(this.props.value, options));
  };

  render() {
    const {
      component: InputComponent = 'input',
      value,
      onChangeText,
      onBlur,
      onAfterBlur,
      options,
      ...props
    } = this.props;

    return (
      <InputComponent
        {...props}
        value={value}
        onChangeText={onChangeText}
        onBlur={(event) => {
          onBlur(event);
          this.handleBlur(event, options);
          onAfterBlur(event);
        }}
      />
    );
  }
}

PercentInputParser.propTypes = {
  options: PropTypes.object,
  onBlur: PropTypes.func,
};

PercentInputParser.defaultProps = {
  options: {},
  onBlur: () => {},
};

export default PercentInputParser;
