// Libraries
import React from 'react';

// Supermove
import {Lifecycle} from '@supermove/components';

import Analytics from './Analytics';

const Identify = ({viewer, children}) => (
  <Analytics.Consumer>
    {(analytics) => (
      <Lifecycle
        onMount={() => {
          analytics.identify(viewer);
        }}
        children={children}
      />
    )}
  </Analytics.Consumer>
);

export default Identify;
