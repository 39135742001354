// Libraries
import React from 'react';

import TimePicker from './TimePicker';

const TimeInput = ({
  disabled,
  component,
  name,
  placeholder,
  value,
  setFieldValue,
  style,
  onChangeTime = () => {},
  ...props
}) => (
  <TimePicker
    {...props}
    disabled={disabled}
    placeholder={placeholder}
    value={value}
    component={component}
    onChange={(value) => {
      setFieldValue(name, value);
      onChangeTime(value);
    }}
    style={style}
  />
);

export default TimeInput;
