// Libraries
import {useCallback, useEffect} from '@supermove/hooks';
import {invariant} from '@supermove/utils';

import {MutationResponse, MutationError} from './types';

type Args<Results> = {
  data: MutationResponse<Results>;
  onSuccess: (results: Results) => void;
  onError: (errors: MutationError[]) => void;
};

const useMutationCallbacks = <Results = Record<string, any>>({
  data,
  onSuccess,
  onError,
}: Args<Results>): void => {
  const onSuccessCallback = useCallback(onSuccess, [data]);
  const onErrorCallback = useCallback(onError, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const {response} = data;

    invariant(
      response,
      `Invalid response. Received: ${JSON.stringify(data)}. Make sure the ` +
        `mutation response is correctly aliased to 'response'.`,
    );

    // Parse the response data into 'errors' and 'response'.
    const {errors, ...rest} = response;

    if (errors) {
      onErrorCallback(errors);
    } else {
      onSuccessCallback(rest as Results);
    }
  }, [data, onSuccessCallback, onErrorCallback]);
};

export default useMutationCallbacks;
