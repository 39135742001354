/**
 * useDebouncedCallback() hook, creates an instance of
 * _.debounce that persists across renders. Useful for
 * debouncing form inputs
 */

// Libraries
import _ from 'lodash';

// Supermove
import {useCallback, useRef} from '@supermove/hooks';

const useDebouncedCallback = (callback, delay, options = {}) => {
  const callbackRef = useRef();
  callbackRef.current = callback;

  return useCallback(
    _.debounce((...args) => callbackRef.current(...args), delay, options),
    [],
  );
};

export default useDebouncedCallback;
