// Libraries
import Geolocation from '@react-x/geolocation';

// Native platform supplies a string (message) to the onError handler. This matches that.
const transformOnError = (onError) => {
  return (error) => onError(error.message);
};

// Native platform takes in seconds.
const transformTimeout = (timeout) => {
  return timeout ? timeout * 1000 : timeout;
};

const BackgroundGeolocation = {
  // HACK(mark): Storing state on a singleton isn't a good idea but works for now.
  watchId: undefined,

  start: async ({timeout, onSuccess, onError}) => {
    console.log('[BackgroundGeolocation]: Starting...');

    // Initialize the watcher.
    BackgroundGeolocation.watchId = Geolocation.watchPosition(
      onSuccess,
      transformOnError(onError),
      {enableHighAccuracy: true, timeout: transformTimeout(timeout)},
    );

    console.log('[BackgroundGeolocation]: Successfully started.');
  },

  stop: async () => {
    Geolocation.clearWatch(BackgroundGeolocation.watchId);
  },

  getCurrentPosition: ({timeout, onSuccess, onError}) => {
    console.log('[BackgroundGeolocation]: Fetching current position.');

    Geolocation.getCurrentPosition(onSuccess, transformOnError(onError), {
      enableHighAccuracy: true,
      timeout: transformTimeout(timeout),
    });
  },
};

export default BackgroundGeolocation;
