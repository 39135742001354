// Libraries
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import BigCalendar from 'react-big-calendar';

// Assets
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';

// const getLocalizer = ({isSundayFirst}) => {
//   if (!isSundayFirst) {
//     moment.updateLocale(
//       'us',
//       {
//         week: {
//           dow: 1,
//           doy: 1,
//         },
//       }
//     );
//   }
//   return BigCalendar.momentLocalizer(moment);
// };

const localizer = BigCalendar.momentLocalizer(moment);

const Calendar = ({
  hasToolbar,
  isSundayFirst,
  date,
  events,
  view,
  views,
  eventPropGetter,
  onNavigate,
  onView,
  dateCellWrapperComponent,
  dateHeaderComponent,
  eventComponent,
  toolbarComponent,
}) => (
  <BigCalendar
    components={{
      dateCellWrapper: dateCellWrapperComponent,
      dateHeader: dateHeaderComponent,
      event: eventComponent,
      toolbar: toolbarComponent,
    }}
    date={moment(date).toDate()}
    drilldownView={null} // Disables drilling down into a single day.
    events={events}
    localizer={localizer}
    toolbar={hasToolbar}
    view={view}
    views={views}
    eventPropGetter={eventPropGetter}
    onNavigate={onNavigate}
    onView={onView}
  />
);

Calendar.propTypes = {
  hasToolbar: PropTypes.bool,
  isSundayFirst: PropTypes.bool,
  date: PropTypes.string.isRequired,
  events: PropTypes.array,
  view: PropTypes.string,
  views: PropTypes.array,
  eventPropGetter: PropTypes.func,
  onNavigate: PropTypes.func,
  onView: PropTypes.func,
  dateCellWrapperComponent: PropTypes.func.isRequired,
  dateHeaderComponent: PropTypes.func,
  eventComponent: PropTypes.func,
};

Calendar.defaultProps = {
  hasToolbar: true,
  isSundayFirst: true,
  events: [],
  view: 'month',
  views: ['month'],
  eventPropGetter: null,
  onNavigate: () => {},
  onView: () => {},
  dateHeaderComponent: null,
  eventComponent: null,
};

export default Calendar;
