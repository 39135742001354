// Libraries
import _ from 'lodash';
import React from 'react';
import toast from 'react-hot-toast';

const useHandleMutationAlertErrors = ({ToastComponent}) => {
  const handleMutationAlertErrors = ({errors}) => {
    const alertErrors = _.filter(errors, (error) => {
      return error.field === 'alert';
    });
    return alertErrors.forEach((error) => {
      toast((toastElement) => (
        <ToastComponent
          message={error.message}
          handleDismiss={() => toast.dismiss(toastElement.id)}
          handleRemove={() => toast.remove(toastElement.id)}
        />
      ));
    });
  };

  return {
    handleMutationAlertErrors,
  };
};

export default useHandleMutationAlertErrors;
