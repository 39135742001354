// Libraries
import React from 'react';

// Supermove
import {Lifecycle} from '@supermove/components';

// Components
import Track from './Track';

// NOTE(mark): Must use the `path` as the `key` to trigger new components to mount when pages change.
const Pageview = ({name, path, tab, children}) => (
  <Track key={path}>
    {(track) => (
      <Lifecycle
        onMount={() => {
          // TODO(mark): Log the `title` as well.
          const event = `Viewed ${name} Page`;
          const data = {
            'Type': 'Pageview',
            'Page Name': name,
            'URL': window.location.pathname,
            ...(tab ? {Tab: tab} : {}),
          };

          // TODO(mark): We need to send the page view on the next tick so that
          // it is always called after 'identify'.
          setTimeout(() => track({event, data}), 0);
        }}
        children={children}
      />
    )}
  </Track>
);

export default Pageview;
