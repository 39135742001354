// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import Popup from 'reactjs-popup';

/**
 * We wrap `trigger` in a `div` because the
 * `reactjs-popup` library expects a DOM node.
 */
const CustomPopup = ({hasArrow, offsetX, position, trigger, contentStyle, children}) => {
  return (
    <Popup
      arrow={hasArrow}
      offsetX={offsetX}
      position={position}
      contentStyle={contentStyle}
      trigger={<div>{trigger}</div>}
    >
      {(close) => children({handleClose: close})}
    </Popup>
  );
};

const POSITION_TOP_LEFT = 'top left';
const POSITION_TOP_RIGHT = 'top right';
const POSITION_BOTTOM_LEFT = 'bottom left';
const POSITION_BOTTOM_RIGHT = 'bottom right';
const POSITION_LEFT_CENTER = 'left center';
const POSITION_RIGHT_CENTER = 'right center';
const POSITION_TOP_CENTER = 'top center';
const POSITION_BOTTOM_CENTER = 'bottom center';
const POSITION_CENTER_CENTER = 'center center';

CustomPopup.Positions = {
  TopLeft: POSITION_TOP_LEFT,
  TopRight: POSITION_TOP_RIGHT,
  BottomLeft: POSITION_BOTTOM_LEFT,
  BottomRight: POSITION_BOTTOM_RIGHT,
  LeftCenter: POSITION_LEFT_CENTER,
  RightCenter: POSITION_RIGHT_CENTER,
  TopCenter: POSITION_TOP_CENTER,
  BottomCenter: POSITION_BOTTOM_CENTER,
  CenterCenter: POSITION_CENTER_CENTER,
};

// --------------------------------------------------
// Props
// --------------------------------------------------
CustomPopup.propTypes = {
  hasArrow: PropTypes.bool,
  offsetX: PropTypes.number,
  position: PropTypes.array,
  contentStyle: PropTypes.object,
  trigger: PropTypes.any.isRequired,
};

CustomPopup.defaultProps = {
  hasArrow: false,
  offsetX: 0,
  position: [POSITION_LEFT_CENTER],
  contentStyle: {},
};

export default CustomPopup;
