module.exports = [{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-react-x/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","map","css"]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-amplitude/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"076e2b86807336c720b86fc70639ce57"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140401595-1"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"4a2a06754e6fea5c3019fc6c72cf8e10"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/apps/manager/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
