// Libraries
import PropTypes from 'prop-types';
import React from 'react';

class Collector extends React.Component {
  state = {
    skipIdentify: false,
    hasIdentified: false,
    queuedEvents: [],
    globalEventData: {},
  };

  constructor(props, context) {
    super(props, context);

    // We cache this in the constructor because we don't want the reference to 'analytics'
    // to change.
    this.analytics = {
      identify: this.handleIdentify,
      skipIdentify: this.handleSkipIdentify,
      track: this.handleTrack,
      reset: this.handleReset,
      setGlobalEventData: this.setGlobalEventData,
    };
  }

  get shouldQueueEvent() {
    return !this.state.hasIdentified && !this.state.skipIdentify;
  }

  setGlobalEventData = (data) => {
    this.setState({globalEventData: data});
  };

  flushQueuedEvents = () => {
    const {queuedEvents} = this.state;
    queuedEvents.forEach((payload) => this.handleTrack(payload));

    // Reset only the queued events but keep the identified state.
    this.setState({queuedEvents: []});
  };

  handleSkipIdentify = () => {
    this.setState({skipIdentify: true}, () => this.flushQueuedEvents());
  };

  handleIdentify = (params) => {
    console.log(`[Analytics]: Identify user: ${params.id}.`);
    this.props.services.map((service) => service.identify(params));
    this.setState({hasIdentified: true}, () => this.flushQueuedEvents());
  };

  handleTrack = ({event, data}) => {
    const {queuedEvents, globalEventData} = this.state;
    const eventData = {...globalEventData, ...data};
    if (this.shouldQueueEvent) {
      this.setState({queuedEvents: [...queuedEvents, {event, data: eventData}]});
    } else {
      console.log(`[Analytics]: Track event: "${event}".`);
      this.props.services.map((service) => service.track({event, data: eventData}));
    }
  };

  handleReset = () => {
    console.log(`[Analytics]: Reset`);
    this.props.services.map((service) => service.reset());

    // Reset the queued events and identified state.
    this.setState({
      skipIdentify: false,
      hasIdentified: false,
      queuedEvents: [],
      globalEventData: {},
    });
  };

  render() {
    return this.props.children(this.analytics);
  }
}

Collector.propTypes = {
  services: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
};

Collector.defaultProps = {};

export default Collector;
