// Supermove
import {useRef} from '@supermove/hooks';

const useScrollView = () => {
  const ref = useRef();
  const handleScrollTo = ({x, y, animated}) => ref.current.scrollTo({x, y, animated});
  const handleScrollToTop = ({animated}) => ref.current.scrollTo({y: 0, animated});
  const handleScrollToEnd = ({animated}) => ref.current.scrollToEnd({animated});

  return {
    ref,
    handleScrollTo,
    handleScrollToTop,
    handleScrollToEnd,
  };
};

export default useScrollView;
