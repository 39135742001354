// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
`;

const ButtonContainer = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-horizontal: ${({isSmall}: {isSmall: boolean}) => (isSmall ? '12px' : '16px')};
  padding-vertical: 8px;
  border-radius: 4px;
  ${({color}: {color: string}) => (color ? `background-color: ${color};` : '')}
  height: ${({height}: {height: string}) => height};
  ${({width}: {width: number}) => (width ? `width: ${width}px;` : '')}
  maxWidth: 100%;
`;

const ButtonContentContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  opacity: ${({isSubmitting}: {isSubmitting: boolean}) => (isSubmitting ? '0' : '100')};
  maxWidth: 100%;
`;

const ButtonText = Styled.Text`
  ${Typography.Label}
  color: ${({color}: {color: string}) => color};
`;

const LargeButtonText = Styled.Text`
  ${Typography.Subheading}
  color: ${({color}: {color: string}) => color};
`;

const ActivityIndicator = Styled.Loading`
  position: absolute;
`;

const LARGE_HEIGHT = '48px';
const DEFAULT_HEIGHT = '36px';
const SMALL_HEIGHT = '28px';

const getHeight = ({
  isSmall,
  isLarge,
  isResponsive,
  responsive,
}: {
  isSmall: boolean;
  isLarge: boolean;
  isResponsive: boolean;
  responsive: any;
}) => {
  const isMobileResponsive = isResponsive && !responsive.desktop;

  if (isSmall) {
    if (isMobileResponsive) {
      return DEFAULT_HEIGHT;
    }
    return SMALL_HEIGHT;
  }

  if (isLarge || isMobileResponsive) {
    return LARGE_HEIGHT;
  }

  return DEFAULT_HEIGHT;
};

const ButtonIcon = ({
  icon,
  size,
  color,
  isResponsive,
  style,
}: {
  icon: any;
  size: number;
  color: string;
  isResponsive: boolean;
  style: object;
}) => {
  const responsive = useResponsive();
  return (
    <Icon
      source={icon}
      size={isResponsive && !responsive.desktop ? size + 2 : size}
      color={color}
      style={style}
    />
  );
};

const ButtonWrapper = ({
  isWidthOfContainer,
  children,
}: {
  isWidthOfContainer: boolean;
  children: React.ReactNode;
}) => {
  if (isWidthOfContainer) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return <Row>{children}</Row>;
};

export interface ButtonProps {
  children?: React.ReactNode;
  width?: number;
  color?: string;
  textColor?: string;
  text?: string;
  onPress?: () => void;
  onLongPress?: () => void;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  iconLeft?: any;
  iconRight?: any;
  isWidthOfContainer?: boolean;
  style?: object;
  iconSize?: number;
  isSmall?: boolean;
  isLarge?: boolean;
  isResponsive?: boolean;
  hitSlop?: object;
  isHitSlop?: boolean;
  slopRadius?: number;
}

const Button = ({
  children,
  width,
  color = colors.blue.interactive,
  textColor = colors.white,
  text,
  onPress = () => {},
  onLongPress = () => {},
  isSubmitting = false,
  isDisabled = false,
  iconLeft = null,
  iconRight = null,
  isWidthOfContainer = false,
  style,
  iconSize,
  isSmall = false,
  isLarge = false,
  isResponsive = false,
  hitSlop,
  isHitSlop = false,
  slopRadius = 16,
}: ButtonProps) => {
  const responsive = useResponsive();

  return (
    <ButtonWrapper isWidthOfContainer={isWidthOfContainer}>
      <ButtonContainer
        isSmall={isResponsive ? isSmall && responsive.desktop : isSmall}
        color={isDisabled || isSubmitting ? colors.gray.disabled : color}
        onPress={onPress}
        onLongPress={onLongPress}
        disabled={isDisabled || isSubmitting}
        width={width}
        height={getHeight({isSmall, isLarge, isResponsive, responsive})}
        style={style}
        activeOpacity={0.8}
        hitSlop={
          hitSlop ||
          (isHitSlop
            ? {top: slopRadius, bottom: slopRadius, left: slopRadius, right: slopRadius}
            : null)
        }
      >
        <ButtonContentContainer isSubmitting={isSubmitting}>
          {!!iconLeft && (
            <ButtonIcon
              icon={iconLeft}
              color={isDisabled ? colors.gray.tertiary : textColor}
              size={iconSize}
              isResponsive={isResponsive}
            />
          )}
          {!!iconLeft && text && <Space width={8} />}
          {isLarge || (!isSmall && isResponsive && !responsive.desktop) ? (
            <LargeButtonText color={isDisabled ? colors.gray.tertiary : textColor}>
              {text}
            </LargeButtonText>
          ) : (
            <ButtonText color={isDisabled ? colors.gray.tertiary : textColor}>{text}</ButtonText>
          )}
          {children}
          {iconRight && (
            <React.Fragment>
              <Space width={9} />
              <ButtonIcon
                icon={iconRight}
                color={isDisabled ? colors.gray.tertiary : textColor}
                size={iconSize}
                isResponsive={isResponsive}
              />
            </React.Fragment>
          )}
        </ButtonContentContainer>
        {isSubmitting && <ActivityIndicator size={'small'} color={textColor} />}
      </ButtonContainer>
    </ButtonWrapper>
  );
};

Button.Text = ButtonText;

// --------------------------------------------------
// Props
// --------------------------------------------------
Button.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  textColor: PropTypes.string,
  onPress: PropTypes.func,
  onLongPress: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  isWidthOfContainer: PropTypes.bool,
  style: PropTypes.object,
};

Button.defaultProps = {
  color: colors.blue.interactive,
  width: null,
  textColor: colors.white,
  onPress: () => {},
  onLongPress: null,
  isSubmitting: false,
  isDisabled: false,
  isWidthOfContainer: false,
  style: null,
};

ButtonContainer.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  style: PropTypes.object,
};

ButtonContainer.defaultProps = {
  color: colors.blue.interactive,
  width: null,
  style: null,
};

ButtonText.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

ButtonText.defaultProps = {
  color: colors.white,
  style: null,
};

ButtonIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
};

ButtonIcon.defaultProps = {
  size: 10,
  color: colors.white,
  style: {},
};

export default Button;
