// Libraries
import _ from 'lodash';

interface ValidationArgs {
  form: any;
  field: string;
  message: string;
}

export interface ValidationErrors {
  [field: string]: string;
}

const Validation = {
  requiredStringField: ({form, field, message}: ValidationArgs): ValidationErrors => {
    if (!_.get(form.values, field) || _.get(form.values, field) === '') {
      return {[field]: message};
    }
    return {};
  },
};

export default Validation;
