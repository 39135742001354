// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Select, {components as ReactSelectComponents} from 'react-select';

// Supermove
import {Space} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors, Typography, INPUT_BORDER_COLOR} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// Components
import DropdownInput from '../DropdownInput';
import Icon from '../Icon';
import Styled from '../Styled';

const RemoveButton = Styled.ButtonV2`
  padding: 4px;
  padding-left: 8px;
  padding-right: 6px;
  align-self: center;
`;

const MultiValueText = Styled.Text`
  ${Typography.MicroLabel}
`;

const TruncateText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
  margin-left: 4px;
`;

const MobileTruncateText = Styled.Text`
  ${Typography.Mobile.Body}
  color: ${colors.gray.tertiary};
  margin-left: 8px;
`;

const controlStyle = ({isMobile}) => ({
  minHeight: isMobile ? 48 : 36,
  paddingTop: 3,
  paddingBottom: 3,
  borderRadius: 3,
  borderWidth: 1,
  borderStyle: 'solid',
  boxShadow: 'none',
  borderColor: INPUT_BORDER_COLOR,
});

const defaultOptionStyle = {
  display: 'block',
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 300,
};

const placeholderStyle = ({isMobile}) => ({
  fontFamily: 'Avenir',
  fontSize: isMobile ? 16 : 14,
  lineHeight: isMobile ? '22px' : '19px',
  fontWeight: 300,
  color: INPUT_BORDER_COLOR,
});

const MultiDropdownInput = ({
  disabled,
  required,
  isSearchable,
  isOptionDisabled,
  isLoading,
  isPortaled,
  hideSelectedOptions,
  blurInputOnSelect,
  closeMenuOnSelect,
  name,
  placeholder,
  menuPlacement,
  value,
  selectedOptions,
  options,
  setFieldValue,
  onChangeValue,
  onSelectValue,
  onDeselectValue,
  onFocus,
  onInputChange,
  components,
  style,
  optionStyle,
  showDescriptionInOption,
  numberOfVisibleSelections,
  isMobile,
  menuIsOpen,
  DropdownIndicatorComponent,
  onBlur,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Select
      isMulti
      menuIsOpen={menuIsOpen}
      // NOTE(cassie): There's an issue with closeMenuOnSelect=false for certain views,
      // adding blurInputOnSelect=false makes sure the select dropdown isn't closing
      blurInputOnSelect={blurInputOnSelect}
      isDisabled={disabled}
      isOptionDisabled={(option) => isOptionDisabled(option)}
      isSearchable={isSearchable}
      isLoading={isLoading}
      hideSelectedOptions={hideSelectedOptions}
      closeMenuOnSelect={closeMenuOnSelect}
      name={name}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      menuPortalTarget={isPortaled && document.body}
      value={selectedOptions || _.map(value, (value) => _.find(options, {value}))}
      options={options}
      formatGroupLabel={DropdownInput.GroupLabel}
      onFocus={() => {
        setIsFocused(true);
        onFocus();
      }}
      onBlur={(event) => {
        onBlur(event);
        setIsFocused(false);
      }}
      onChange={(values, event) => {
        const value = values.map((value) => value.value);
        setFieldValue(name, value);

        // TODO(mark): Hack to avoid the Formik name: onChange.
        onChangeValue(value, event);
        if (event.action === 'select-option') {
          onSelectValue(event.option.value, event);
        }
        if (event.action === 'deselect-option') {
          onDeselectValue(event.option.value, event);
        }
        if (event.action === 'remove-value') {
          onDeselectValue(event.removedValue.value, event);
        }
      }}
      onInputChange={(value) => onInputChange(value)}
      components={{
        ClearIndicator: () => null,
        IndicatorSeparator: () => null,
        MultiValueRemove: (props) => {
          return (
            <RemoveButton onPress={props.innerProps.onClick}>
              <Icon
                {...props}
                source={Icon.XmarkLarge}
                size={isMobile ? 12 : 8}
                color={props.data.hasError ? colors.red.warning : colors.gray.secondary}
              />
              {isMobile && <Space width={20} />}
            </RemoveButton>
          );
        },
        MultiValue: (props) => {
          const selectedValues = props.selectProps.value;

          if (!isFocused && numberOfVisibleSelections) {
            const index = _.findIndex(selectedValues, ['value', props.data.value]);
            if (index === numberOfVisibleSelections) {
              const overCount = selectedValues.length - numberOfVisibleSelections;
              const truncatedText = `+${pluralize('other', overCount, true)}`;
              if (isMobile) {
                return <MobileTruncateText>{truncatedText}</MobileTruncateText>;
              }
              return <TruncateText>{truncatedText}</TruncateText>;
            }

            if (index > numberOfVisibleSelections) {
              return null;
            }
          }

          return (
            <ReactSelectComponents.MultiValue {...props}>
              <MultiValueText>{props.data.label}</MultiValueText>
            </ReactSelectComponents.MultiValue>
          );
        },
        Group: (props) => {
          const allOptions = props.selectProps.options;
          const isFirstGroup = _.get(props, 'data.label') === _.get(allOptions, '0.label');
          const isLastGroup =
            _.get(props, 'data.label') === _.get(allOptions, `${allOptions.length - 1}.label`);

          return (
            <div id={'revertGlobalStyles'}>
              <DropdownInput.GroupContainer>
                {!isFirstGroup && <DropdownInput.GroupDivider />}
                <Space height={12} />
                <ReactSelectComponents.Group {...props} />
                {!isLastGroup && <Space height={4} />}
              </DropdownInput.GroupContainer>
            </div>
          );
        },
        Option: showDescriptionInOption
          ? DropdownInput.OptionWithDescription
          : DropdownInput.Option,
        ...(DropdownIndicatorComponent
          ? {
              DropdownIndicator: (props) => {
                return (
                  <ReactSelectComponents.DropdownIndicator {...props}>
                    <DropdownIndicatorComponent />
                  </ReactSelectComponents.DropdownIndicator>
                );
              },
            }
          : {
              DropdownIndicator: (props) => {
                return (
                  <MultiDropdownInput.DropdownIndicator {...props}>
                    <Icon
                      source={Icon.ChevronDown}
                      size={14}
                      color={colors.gray.secondary}
                      style={{marginLeft: 4, marginRight: 4, marginTop: 2}}
                    />
                  </MultiDropdownInput.DropdownIndicator>
                );
              },
            }),
        ...components,
      }}
      styles={{
        container: (current, state) => ({
          ...current,
          pointerEvents: 'auto',
          cursor: state.isDisabled ? 'not-allowed' : 'text',
        }),
        control: (current, state) => ({
          ...current,
          '&:hover': {
            borderColor: disabled
              ? INPUT_BORDER_COLOR
              : state.isFocused
                ? colors.blue.interactive
                : colors.blue.hover,
          },
          ...controlStyle({isMobile}),
          ...style,
          ...(state.isFocused ? {borderColor: colors.blue.interactive} : {}),
          'pointerEvents': state.isDisabled ? 'none' : 'auto',
          'cursor': disabled ? 'default' : 'text',

          // Height should always be null to allow the area to expand.
          'height': null,

          // Custom 'required' feature to change the background-color.
          'backgroundColor': DropdownInput.getBackgroundColor({
            disabled,
            required,
          }),
        }),
        placeholder: (current) => ({
          ...current,
          ...placeholderStyle({isMobile}),
        }),
        option: (current) => ({
          ...current,
          ...defaultOptionStyle,
          ...optionStyle,
        }),
        menu: (current) => ({
          ...current,
          display: 'block',
        }),
        menuList: (current) => ({
          ...current,
          display: 'block',
          paddingTop: 4,
          paddingBottom: 4,
        }),
        multiValue: (current, state) => ({
          ...current,
          marginRight: isMobile ? '8px' : '4px',
          backgroundColor: state.data.hasError ? colors.red.accent : colors.gray.border,
        }),
        input: (current) => ({
          ...current,
          fontSize: isMobile ? 16 : 14,
        }),
      }}
    />
  );
};

MultiDropdownInput.Option = ({optionComponentProps, OptionComponent, ...props}) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    data,
  } = props;
  return (
    <div
      ref={innerRef}
      css={getStyles('option', props)}
      className={cx(
        {
          'option': true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      {...innerProps}
    >
      <OptionComponent option={data} disabled={isDisabled} {...optionComponentProps}>
        {children}
      </OptionComponent>
    </div>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
MultiDropdownInput.propTypes = {
  value: PropTypes.array,
  numberOfVisibleSelections: PropTypes.number,
  isSearchable: PropTypes.bool,
  isPortaled: PropTypes.bool,
  blurInputOnSelect: PropTypes.bool,
  isMobile: PropTypes.bool,
  onInputChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  onChangeValue: PropTypes.func,
  onSelectValue: PropTypes.func,
  onDeselectValue: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  components: PropTypes.object,
  optionStyle: PropTypes.object,
};

MultiDropdownInput.defaultProps = {
  value: [],
  numberOfVisibleSelections: null,
  isSearchable: true,
  isPortaled: false,
  blurInputOnSelect: false,
  isMobile: false,
  onInputChange: () => {},
  setFieldValue: () => {},
  isOptionDisabled: () => {},
  onChangeValue: () => {},
  onSelectValue: () => {},
  onDeselectValue: () => {},
  onFocus: () => {},
  onBlur: () => {},
  components: {},
  optionStyle: {},
};

MultiDropdownInput.Option.propTypes = {
  optionComponentProps: PropTypes.object,
};

MultiDropdownInput.Option.defaultProps = {
  optionComponentProps: {},
};

MultiDropdownInput.DropdownIndicator = ReactSelectComponents.DropdownIndicator;

export default MultiDropdownInput;
