const MANAGER = 'Manager';
const ADMIN = 'Admin';
const CUSTOMER = 'Customer';
const ESTIMATOR = 'Estimator';
const CREW = 'Crew';

const AppName = {
  MANAGER,
  ADMIN,
  CUSTOMER,
  ESTIMATOR,
  CREW,
};

export default AppName;
