// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Lifecycle} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import LoadingButton from './LoadingButton';

const StatefulButton = ({
  color,
  disabled,
  disabledColor,
  error,
  errorColor,
  loading,
  onError,
  onSuccess,
  success,
  successColor,
  children,
  ...props
}) => (
  <Lifecycle
    error={error}
    onUpdate={(previousProps) => {
      if (success && previousProps.success !== success) {
        setTimeout(() => onSuccess(), 1000);
      } else if (error && previousProps.error !== error) {
        setTimeout(() => onError(), 1000);
      }
    }}
    success={success}
  >
    <LoadingButton
      disabled={error || disabled || loading || success}
      loading={loading}
      {...props}
      disabledColor={error ? errorColor : success ? successColor : disabledColor}
      style={success || error ? {transitionProperty: 'all', transitionDuration: '0.5s'} : {}}
    >
      {children}
    </LoadingButton>
  </Lifecycle>
);

StatefulButton.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorColor: PropTypes.string,
  loading: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  success: PropTypes.bool,
  successColor: PropTypes.string,
};

StatefulButton.defaultProps = {
  disabled: false,
  error: false,
  errorColor: colors.Pink600,
  loading: false,
  onError: () => {},
  onSuccess: () => {},
  success: false,
  successColor: colors.green.status,
};

export default StatefulButton;
