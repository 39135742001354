// Libraries
import _ from 'lodash';

// Supermove
import {useNavigation, useNavigationDOM, useState} from '@supermove/hooks';
import {Datetime, URL} from '@supermove/utils';

const useUrlFilters = ({
  filterKeys,
  filtersForReset,
  filtersForUpdate,
  onUpdate,
  getRoute,
  navigationLib = 'react-router-dom',
}) => {
  const {navigator: reactRouterNavigator, params: routerDomParams} = useNavigationDOM();
  const {navigator: reactNavigationNavigator} = useNavigation();

  const currentParams =
    navigationLib === 'react-router-dom' ? routerDomParams : reactNavigationNavigator.state.params;

  const [resetKey, setResetKey] = useState(0);

  const getFilters = () => {
    return _.reduce(
      filterKeys,
      (result, key) => ({
        ...result,
        [key]: _.get(currentParams, key),
      }),
      {},
    );
  };

  const handleUpdate = (updatedFilters, isPushRoute) => {
    const currentFilters = getFilters();
    const filters = {
      ...currentFilters,
      ...(filtersForUpdate || {}),
      ...updatedFilters,
    };

    _navigate({
      navigationLib,
      reactRouterNavigator,
      reactNavigationNavigator,
      route: getRoute(),
      filters,
      isPushRoute,
    });

    onUpdate && onUpdate(filters);
  };

  const handleUpdateDate = ({date, dateKey}, isPushRoute) => {
    handleUpdate({[dateKey]: date ? Datetime.convertToDate(date) : ''}, isPushRoute);
  };

  const handleReset = (customFilters, isPushRoute) => {
    const resetFilters = _.reduce(
      filterKeys,
      (result, key) => ({
        ...result,
        [key]: undefined,
      }),
      {},
    );

    const newFilters = {
      ...resetFilters,
      ...(filtersForReset || {}),
      ...(customFilters || {}),
    };

    _navigate({
      navigationLib,
      reactRouterNavigator,
      reactNavigationNavigator,
      route: getRoute(),
      filters: newFilters,
      isPushRoute,
    });

    setResetKey(resetKey + 1);
  };

  const getInputValueForDate = ({dateKey}) => {
    const urlDateValue = currentParams[dateKey];
    const isValidDate = Datetime.isValidDateString(urlDateValue);
    if (isValidDate) {
      return Datetime.fromDate(urlDateValue);
    }
    return '';
  };

  const getFilterCount = ({filterKeys: selectKeys} = {}) => {
    const filters = _.reduce(
      selectKeys || filterKeys,
      (result, key) => {
        const value = _.get(currentParams, key);
        if (_.isArray(value) && value.length === 0) {
          return result;
        }
        return [...result, value];
      },
      [],
    );

    return _.compact(filters).length;
  };

  const getFilterCountLabel = ({filterKeys: selectKeys} = {}) => {
    const filterCount = getFilterCount({filterKeys: selectKeys});

    return `Filters${filterCount ? ` (${filterCount})` : ''}`;
  };

  const get = (filterKey) => {
    return _.get(currentParams, filterKey);
  };

  return {
    // Handlers
    handleUpdate, // Set any amount of filters
    handleUpdateDate, // For updating a Datetime (eg. from DateInput)
    handleReset, // Can take filters to set
    resetKey,

    // Input Helpers
    getInputValueForDate, // Transform date string to Datetime

    // Getters
    get, // Takes only a key as an argument
    getFilters, // Can use for query variables
    getFilterCount, // Count of applied filters
    getFilterCountLabel, // eg. "Filters (2)"
  };
};

const _navigate = ({
  navigationLib,
  reactRouterNavigator,
  reactNavigationNavigator,
  route,
  filters,
  isPushRoute,
}) => {
  if (navigationLib === 'react-router-dom') {
    const navigate = _getNavigate({navigator: reactRouterNavigator, isPushRoute});
    navigate(URL.getUrlFromVariables(route, filters));
  } else {
    const navigate = _getNavigate({navigator: reactNavigationNavigator, isPushRoute});
    navigate(reactNavigationNavigator.state.routeName, {
      ...reactNavigationNavigator.state.params,
      ...filters,
    });
  }
};

const _getNavigate = ({navigator, isPushRoute}) => {
  if (isPushRoute) {
    return navigator.push;
  }
  return navigator.replace;
};

export default useUrlFilters;
