// Libraries
import PropTypes from 'prop-types';
import React from 'react';

class UnitInputParser extends React.Component {
  getMatches = (string, regex) => {
    return string.match(regex) || [];
  };

  formatValue = (value) => {
    if (value === '') {
      return '';
    }

    // Allows for numbers with an optional decimal.
    const numerics = this.getMatches(value, /\d+[.\d+]?/g).join('');
    const alpha = this.getMatches(value, /[a-zA-Z]+/g)
      .join('')
      .toLowerCase();

    if (alpha.length > 0) {
      return '';
    }

    const float = parseFloat(numerics) || 0;

    return `${float} ${this.props.unitString}`;
  };

  handleBlur = (event, options) => {
    this.props.onChangeText(this.formatValue(this.props.value, options));
  };

  render() {
    const {
      component: InputComponent = 'input',
      value,
      onChangeText,
      onBlur,
      options,
      ...props
    } = this.props;

    return (
      <InputComponent
        {...props}
        value={value}
        onChangeText={onChangeText}
        onBlur={(event) => {
          onBlur(event);
          this.handleBlur(event, options);
        }}
      />
    );
  }
}

UnitInputParser.propTypes = {
  options: PropTypes.object,
  onBlur: PropTypes.func,
  unitString: PropTypes.string.isRequired,
};

UnitInputParser.defaultProps = {
  options: {},
  onBlur: () => {},
};

export default UnitInputParser;
