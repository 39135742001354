// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Analytics, useAnalytics} from '@supermove/analytics';
import {ErrorView, OfflineOverlay, Query} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useInternet, useMountEffect} from '@supermove/hooks';
import {Platform, Environment, Storage} from '@supermove/sdk';
import {GlobalStyle} from '@supermove/styles';

const Viewer = ({skip, children}) => (
  <Query
    skip={skip}
    fetchPolicy={'cache-first'}
    query={Viewer.query}
    onCompleted={updateExperiments}
  >
    {({loading, data, error}) =>
      children({
        loading,
        isAuthenticated: _.get(data, 'isAuthenticated'),
        viewer: _.get(data, 'viewer'),
      })
    }
  </Query>
);

Viewer.query = gql`
  query Viewer {
    ${gql.query}
    isAuthenticated
    viewer {
      id
      fullName
      email
      phoneNumber
      role
      organization {
        id
        slug
      }
      viewingOrganization {
        id
        slug
        features {
          isEnabledXAPIExperimental: isEnabled(feature: "X_API_EXPERIMENTAL")
          isEnabledOfficeZendeskSupportWidget: isEnabled(feature: "OFFICE_ZENDESK_SUPPORT_WIDGET")
          isEnabledOfficeBrainfishSupportWidget: isEnabled(feature: "OFFICE_BRAINFISH_SUPPORT_WIDGET")
        }
      }
    }
  }
`;

const updateExperiments = (data) => {
  if (_.get(data, 'viewer.organization.features.isEnabledXAPIExperimental')) {
    Storage.setItem('X_API_EXPERIMENTAL', 'true');
  } else {
    Storage.setItem('X_API_EXPERIMENTAL', 'false');
  }
};

const getUserInfo = ({viewer}) => {
  return {
    // Sentry-specific keys
    id: _.get(viewer, 'id', ''),
    email: _.get(viewer, 'email', ''),
    username: _.get(viewer, 'fullName', ''),

    // Custom
    ID: _.get(viewer, 'id', ''),
    Name: _.get(viewer, 'fullName', ''),
    Email: _.get(viewer, 'email', ''),
    Phone: _.get(viewer, 'phoneNumber', ''),
    Role: _.get(viewer, 'role', ''),
    Organization: _.get(viewer, 'organization.slug', ''),
    ViewingOrganization: _.get(viewer, 'viewingOrganization.slug', ''),
  };
};

const getPlatform = () => {
  return Platform.select({
    android: 'Android',
    ios: 'iOS',
    web: 'Web',
    default: 'Unknown',
  });
};

const getExtraInfo = ({isAuthenticated, version, buildNumber}) => {
  return {
    'Is Authenticated': isAuthenticated,
    'App Version': version,
    'App Build': buildNumber,
    'App Name': Environment.getAppName(),
    'App Platform': getPlatform(),
  };
};

const getTags = ({isAuthenticated, viewer, version, buildNumber}) => {
  return {
    // App info
    app_build: buildNumber,
    app_name: Environment.getAppName(),
    app_platform: getPlatform(),
    app_version: version,

    // User info
    user_email: _.get(viewer, 'email', ''),
    user_id: _.get(viewer, 'id', ''),
    user_is_authenticated: isAuthenticated,
    user_name: _.get(viewer, 'fullName', ''),
    user_phone_number: _.get(viewer, 'phoneNumber', ''),
    user_role: _.get(viewer, 'role', ''),
    user_organization: _.get(viewer, 'organization.slug', ''),
    user_viewing_organization: _.get(viewer, 'viewingOrganization.slug', ''),
  };
};

const GlobalAnalytics = ({name, version, buildNumber, children}) => {
  const analytics = useAnalytics();
  useMountEffect(() => {
    analytics.setGlobalEventData({name, version, buildNumber});
  });

  return children;
};

const SupermoveApp = ({
  showOfflineOverlay,
  skipViewer,
  name,
  version,
  buildNumber,
  onError,
  children,
}) => {
  useMountEffect(() => {
    console.log(
      `[App]: Starting "${name} v${version}" build ${buildNumber} in ` +
        `environment: "${Environment.getAppEnv()}".`,
    );
  });

  const {hasFetched, hasConnected, isConnected} = useInternet();

  if (!hasFetched) {
    return null;
  }

  return (
    <React.Fragment>
      <GlobalStyle />
      {(hasConnected || Environment.isLocal()) && (
        <Analytics
          amplitude={{
            apiKey: Environment.get('AMPLITUDE_API_KEY'),
          }}
          mixpanel={{
            token: Environment.get('MIXPANEL_TOKEN'),
          }}
          uxcam={{
            apiKey: Environment.get('UXCAM_API_KEY'),
          }}
          userflow={{
            apiKey: Environment.get('USERFLOW_KEY'),
          }}
          sentry={{}} // Initialization happens in the ErrorView component
        >
          <Viewer skip={skipViewer}>
            {({isAuthenticated, viewer}) => (
              <GlobalAnalytics name={name} version={version} buildNumber={buildNumber}>
                <ErrorView
                  user={getUserInfo({viewer})}
                  extra={getExtraInfo({isAuthenticated, version, buildNumber})}
                  tags={getTags({isAuthenticated, viewer, version, buildNumber})}
                  onError={onError}
                >
                  {children({viewer})}
                </ErrorView>
              </GlobalAnalytics>
            )}
          </Viewer>
        </Analytics>
      )}
      {showOfflineOverlay && !isConnected && !Environment.isLocal() && <OfflineOverlay />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SupermoveApp.propTypes = {
  children: PropTypes.func.isRequired,
  onError: PropTypes.func,
  showOfflineOverlay: PropTypes.bool,
  skipViewer: PropTypes.bool,
};

SupermoveApp.defaultProps = {
  onError: () => {},
  showOfflineOverlay: true,
  skipViewer: false,
};

export default SupermoveApp;
