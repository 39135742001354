// Libraries
import {useMediaQuery} from 'react-responsive';

const defaults = {
  desktop: false,
  tablet: false,
  mobile: false,
};

const useResponsive = () => {
  const isLargeDesktop = useMediaQuery({minWidth: 1200});
  const isDesktop = useMediaQuery({minWidth: 1032});
  const isTablet = useMediaQuery({minWidth: 768});

  if (isLargeDesktop) {
    return {...defaults, desktop: true, large: true};
  } else if (isDesktop) {
    return {...defaults, desktop: true};
  } else if (isTablet) {
    return {...defaults, tablet: true};
  } else {
    // Anything smaller is mobile.
    return {...defaults, mobile: true};
  }
};

export default useResponsive;
