/* stylelint-disable selector-type-no-unknown */

// Libraries
import styled, {css} from 'styled-components';

// App
import colors from './colors';
import {Typography} from './typography';

export const INPUT_BORDER_COLOR = colors.gray.tertiary;

export const placeholderStyle = css`
  color: ${INPUT_BORDER_COLOR};
  opacity: 1;
`;

export const hoverStyle = css`
  border-color: ${colors.blue.hover} !important;
`;

export const hoverDisabledStyle = css`
  border-color: ${colors.gray.tertiary} !important;
`;

export const focusStyle = css`
  border-color: ${colors.blue.interactive} !important;
`;

export const hoverFocusStyle = css`
  border-color: ${colors.blue.interactive} !important;
`;

// Works with react-native, react-x, and all cross-platform styles.
export const textInputStyle = css`
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0px;
  background-color: ${(props) => {
    if (props.disabled) {
      return colors.gray.background;
    } else if (props.required) {
      return colors.alpha(colors.yellow.hover, 0.1);
    } else {
      return colors.white;
    }
  }};
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${INPUT_BORDER_COLOR};
`;

export const textInputValueStyle = css`
  color: ${(props) => (props.disabled ? colors.gray.tertiary : colors.gray.primary)};
`;

// Use this when injecting styles into a 3rd party library that only works with
// the DOM.
export const domTextInputStyle = css`
  ${textInputStyle}
  ${textInputValueStyle}

  ::placeholder {
    ${placeholderStyle}
  }

  ::-webkit-input-placeholder {
    ${placeholderStyle}
  }

  ::-ms-input-placeholder {
    ${placeholderStyle}
  }

  :-ms-input-placeholder {
    ${placeholderStyle}
  }
`;

export const createStyledDOMInput = (Component) => {
  return styled(Component)`
    ${Typography.Body}
    ${domTextInputStyle}
    ${(props) => props.style}
  `;
};
