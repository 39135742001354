// Libraries
import PropTypes from 'prop-types';

// Components
import Styled from '../Styled';

const Space = Styled.View`
  ${(props) => (props.height ? `height: ${props.height}px` : '')}
  ${(props) => (props.width ? `width: ${props.width}px` : '')}
  ${(props) => (props.flex ? `flex: ${props.flex}` : '')}
`;

// --------------------------------------------------
// Props
// --------------------------------------------------
Space.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

Space.defaultProps = {
  height: 0,
  width: 0,
};

export default Space;
