import _ from 'lodash';

// Supermove
import {
  createCache,
  createClient,
  createAuthenticationMiddleware,
  createErrorMiddleware,
  createGraphQLMiddleware,
  createHeadersMiddleware,
  createRetryMiddleware,
} from '@supermove/graphql';

// Modules
import Config from '../Config';
import Environment from '../Environment';
import Storage from '../Storage';

import onGraphQLError from './onGraphQLError';

const getOperationType = (operation) => {
  const definitions = _.get(operation, 'query.definitions', []);
  const operationDefinition = definitions.find(
    (definition) => definition.kind === 'OperationDefinition',
  );
  if (operationDefinition) {
    return operationDefinition.operation;
  }
};

const GraphQL = {
  createCache: () => {
    return createCache();
  },

  createAppSyncClient: ({cache, resolvers}) => {
    return createClient({
      name: 'AppSync',
      connectToDevTools: Environment.isLocal(),
      cache,
      resolvers,
      middleware: [
        createErrorMiddleware({onError: onGraphQLError}),
        createHeadersMiddleware({
          getHeaders: () => {
            return {
              'x-api-key': process.env.APPSYNC_API_KEY,
              'Content-Type': 'application/graphql',
            };
          },
        }),
        // GraphQL middleware must go last after we have authenticated.
        createGraphQLMiddleware({uri: `${Config.getAppSyncAPIHost()}/graphql`}),
      ],
    });
  },

  createClient: ({cache, resolvers, appInfo}) => {
    // Create the graphql client with authentication support.
    return createClient({
      connectToDevTools: Environment.isLocal(),
      cache,
      resolvers,
      middleware: [
        createRetryMiddleware({
          delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
          },
          attempts: {
            max: Environment.isLocal() ? 0 : 3,
            retryIf: (error, operation) => {
              const isQuery = getOperationType(operation) !== 'mutation';
              return Boolean(error) && isQuery;
            },
          },
        }),
        // First, catch any errors and log them.
        createErrorMiddleware({onError: onGraphQLError}),

        createHeadersMiddleware({
          getHeaders: async () => {
            const isExperimental = await Storage.getItem('X_API_EXPERIMENTAL');
            const amplitudeSessionId = await Storage.getItem('amplitude_session_id');
            // 'location' is undefined on react-native
            const clientUrl = window.location
              ? {
                  'x-supermove-client-url': `${window.location.origin}${window.location.pathname}`,
                }
              : {};

            return {
              'x-api-experimental': isExperimental === 'true',
              'x-amplitude-session-id': amplitudeSessionId,
              'x-supermove-app-name': appInfo.name,
              'x-supermove-app-version': appInfo.getVersion(),
              'x-supermove-app-build': appInfo.getBuildNumber(),
              'x-supermove-app-platform': appInfo.platform,
              ...clientUrl,
            };
          },
        }),
        // If we're able to connect, then authenticate with our token.
        createAuthenticationMiddleware({
          getToken: async () => {
            const token = await Storage.getItem('token');
            return token ? `Bearer ${token}` : null;
          },
        }),

        // GraphQL middleware must go last after we have authenticated.
        createGraphQLMiddleware({uri: `${Config.getAPIHost()}/graphql`}),
      ],
      defaultOptions: {
        query: {
          fetchPolicy: 'cache-and-network',
        },
      },
    });
  },
  onGraphQLError,
};

export default GraphQL;
