// Libraries
import PropTypes from 'prop-types';
import React from 'react';

import {colors} from '@supermove/styles';

import CurrencyPicker from './CurrencyPicker';

const CurrencyInput = ({
  disabled,
  shouldHideCentsIfZero,
  keyboardType,
  component,
  name,
  placeholder,
  value,
  onChange,
  onChangeText,
  onBlur,
  setFieldValue,
  setFieldTouched,
  style,
  options,
  ...props
}) => (
  <CurrencyPicker
    {...props}
    name={name}
    disabled={disabled}
    shouldHideCentsIfZero={shouldHideCentsIfZero}
    keyboardType={keyboardType}
    placeholder={placeholder}
    value={value}
    component={component}
    onBlur={(event) => {
      onBlur(event);
      setFieldTouched(name, true);
    }}
    onChangeText={(value) => {
      setFieldValue(name, value);
      onChangeText(value);
    }}
    style={{
      color: colors.gray.primary,
      backgroundColor: disabled ? colors.gray.disabled : colors.white,
      ...style,
    }}
    options={options}
  />
);

// --------------------------------------------------
// Props
// --------------------------------------------------
CurrencyInput.propTypes = {
  shouldHideCentsIfZero: PropTypes.bool,
  keyboardType: PropTypes.string,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  onChangeText: PropTypes.func,
};

CurrencyInput.defaultProps = {
  shouldHideCentsIfZero: false,
  // phone-pad, used on native, does not allow for decimal points to be used on iOS
  // in order for mobile web to allow proper inputs, we utilize the default iOS/Android keyboard
  keyboardType: 'default',
  onBlur: () => {},
  onChangeText: (value) => {},
};

export default CurrencyInput;
