// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import AnalyticsContext from '../context/AnalyticsContext';
import Amplitude from '../services/Amplitude';
import Mixpanel from '../services/Mixpanel';
import Sentry from '../services/Sentry';
import UXCam from '../services/UXCam';
import Userflow from '../services/Userflow';

import Collector from './Collector';

const AnalyticsContent = ({amplitude, mixpanel, uxcam, userflow, sentry, children}) => {
  return (
    <Collector services={[uxcam, mixpanel, amplitude, userflow, sentry]}>
      {(analytics) => <Analytics.Provider value={analytics}>{children}</Analytics.Provider>}
    </Collector>
  );
};

// TODO(mark): React Hooks would clean up this nesting.
const Analytics = ({amplitude, mixpanel, uxcam, userflow, sentry, children}) => {
  return (
    <UXCam {...uxcam}>
      {(uxcam) => (
        <Mixpanel {...mixpanel}>
          {(mixpanel) => (
            <Amplitude {...amplitude}>
              {(amplitude) => (
                <Userflow {...userflow}>
                  {(userflow) => (
                    <Sentry {...sentry}>
                      {(sentry) => (
                        <AnalyticsContent
                          amplitude={amplitude}
                          mixpanel={mixpanel}
                          uxcam={uxcam}
                          userflow={userflow}
                          sentry={sentry}
                          children={children}
                        />
                      )}
                    </Sentry>
                  )}
                </Userflow>
              )}
            </Amplitude>
          )}
        </Mixpanel>
      )}
    </UXCam>
  );
};

Analytics.Provider = AnalyticsContext.Provider;
Analytics.Consumer = AnalyticsContext.Consumer;

Analytics.propTypes = {
  amplitude: PropTypes.object,
  mixpanel: PropTypes.object,
  uxcam: PropTypes.object,
};

Analytics.defaultProps = {
  amplitude: {},
  mixpanel: {},
  uxcam: {},
};

export default Analytics;
