/**
 * Component - v2.1.0
 */

// Libraries
import Tippy from '@tippyjs/react/headless';
import PropTypes from 'prop-types';
import React from 'react';
import {sticky, followCursor} from 'tippy.js';

/*
 * Add popover content by passing in a component into the *content* argument.
 * Wrap the static object with the Popover object like:
 *
 *  const {ref, isOpen, handleOpen, handleClose, handleToggle} = usePopover();
 *  <span tabIndex={'0'} ref={ref} />
 *    <SomeTouchable onPress={onPress} />}
 *  </span>
 *  <Popover
 *     placement={Popover.Positions.BottomStart}
 *     isOpen={isOpen}
 *     handleOpen={handleOpen}
 *     handleClose={handleClose}
 *     reference={ref}
 *   >
 *    <ContentYouWantToDisplay />
 *  </Popover>
 * To update this component, refer to https://atomiks.github.io/tippyjs/v6/all-props/ for more customizations.
 */
const Popover = ({
  handleOpen,
  handleClose,
  reference,
  isOpen,
  children,
  isClosableOutside,
  placement,
  initialPlacementOnClick,
  offset,
  arrow,
  onComponentDidHide,
  zIndex,
}) => {
  return (
    <Tippy
      placement={placement}
      theme={'light'}
      offset={offset} // Add x,y coordinate offset from reference element
      arrow={arrow}
      render={() => children}
      visible={isOpen}
      onShow={handleOpen}
      onClickOutside={isClosableOutside ? handleClose : () => {}} // Use handleClose on visible if this is desired
      appendTo={document.body} // Handles interactive mode, to put above everything else
      reference={reference}
      plugins={[sticky, followCursor]}
      interactive
      onHidden={onComponentDidHide}
      sticky
      zIndex={zIndex}
      followCursor={initialPlacementOnClick ? 'initial' : false}
    />
  );
};

const POSITION_AUTO = 'auto';
const POSITION_AUTO_START = 'auto-start';
const POSITION_AUTO_END = 'auto-end';
const POSITION_TOP = 'top';
const POSITION_TOP_START = 'top-start';
const POSITION_TOP_END = 'top-end';
const POSITION_RIGHT = 'right';
const POSITION_RIGHT_START = 'right-start';
const POSITION_RIGHT_END = 'right-end';
const POSITION_BOTTOM = 'bottom';
const POSITION_BOTTOM_START = 'bottom-start';
const POSITION_BOTTOM_END = 'bottom-end';
const POSITION_LEFT = 'left';
const POSITION_LEFT_START = 'left-start';
const POSITION_LEFT_END = 'left-end';

Popover.Positions = {
  Auto: POSITION_AUTO,
  AutoStart: POSITION_AUTO_START,
  AutoEnd: POSITION_AUTO_END,
  Top: POSITION_TOP,
  TopStart: POSITION_TOP_START,
  TopEnd: POSITION_TOP_END,
  Bottom: POSITION_BOTTOM,
  BottomStart: POSITION_BOTTOM_START,
  BottomEnd: POSITION_BOTTOM_END,
  Right: POSITION_RIGHT,
  RightStart: POSITION_RIGHT_START,
  RightEnd: POSITION_RIGHT_END,
  Left: POSITION_LEFT,
  LeftStart: POSITION_LEFT_START,
  LeftEnd: POSITION_LEFT_END,
};

// We use *innerRef* because we need to pass the ref to the span to get
// right positioning and we don't want to alias to React's *ref* prop.
const Content = ({innerRef, children}) => {
  return (
    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    <span tabIndex={'0'} ref={innerRef}>
      {children}
    </span>
    /* eslint-enable jsx-a11y/no-noninteractive-tabindex */
  );
};

Popover.Content = Content;

// --------------------------------------------------
// Props
// --------------------------------------------------
Popover.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  reference: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isClosableOutside: PropTypes.bool,
  placement: PropTypes.string,
  offset: PropTypes.array,
  arrow: PropTypes.bool,
  onComponentDidHide: PropTypes.func,
  zIndex: PropTypes.number,
};

Popover.defaultProps = {
  isClosableOutside: true,
  placement: POSITION_AUTO,
  offset: [0, 0],
  arrow: false,
  onComponentDidHide: () => {},
  zIndex: 0,
};

export default Popover;
