// Libraries
import {NavigationContext} from '@react-navigation/core';
import _ from 'lodash';
import {useContext} from 'react';

const getParams = ({state}) => {
  const combiner = (params, route) => {
    const routes = _.get(route, 'routes', []);

    return {
      ...params,
      ...route.params,
      ..._.reduce(routes, combiner, {}),
    };
  };

  return _.reduce([state], combiner, {});
};

const useNavigation = () => {
  const navigator = useContext(NavigationContext);
  const state = _.get(navigator, 'state', {});
  const params = getParams({state});

  return {
    navigator,
    params,
  };
};

export default useNavigation;
