// Libraries
import {Emoji} from '@increment/components';
import React from 'react';

// App
import {colors, Typography} from '@supermove/styles';

// Components
import Styled from '../Styled';

const Overlay = Styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(74, 74, 74, 0.7);
`;

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  width: ${(props) => props.width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
  margin-bottom: 20px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  font-weight: 500;
  text-align: center;
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const OfflineOverlay = () => (
  <Overlay>
    <Container>
      <Wrapper width={350}>
        <Circle>
          <Emoji component={EmojiText} name={'globe_with_meridians'} />
        </Circle>
        <Content>
          <Title>It appears you are offline</Title>
          <Subtitle>
            Please reconnect to the internet. This dialog will automatically disappear when you are
            connected.
          </Subtitle>
        </Content>
      </Wrapper>
    </Container>
  </Overlay>
);

export default OfflineOverlay;
