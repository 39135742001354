// Supermove
import {pluralize} from '@supermove/utils';

const Weight = {
  display: (weight) => {
    return pluralize('lb', weight, true);
  },
};

export default Weight;
