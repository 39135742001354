const HTML = {
  fixRichTextEditor: (html) => {
    const fullTag = new RegExp('<p><br></p>', 'g');
    const openTag = new RegExp(`<p>`, 'g');
    const closeTag = new RegExp(`</p>`, 'g');
    return html.replace(fullTag, '<br>').replace(openTag, '').replace(closeTag, '<br>');
  },
  removeElement: (html, element) => {
    const openTag = new RegExp(`<${element}>`, 'g');
    const closeTag = new RegExp(`</${element}>`, 'g');
    return html.replace(openTag, '').replace(closeTag, '');
  },
};

export default HTML;
