import Sleep from './Sleep';

const Retry = {
  /**
   * Calls the given function and retries it if an error is thrown. This will continue to retry
   * every retryIntervalMs until the timeout is exhausted.
   */
  withTimeout: (func, timeoutMs, retryIntervalMs = 500) => {
    return Retry.withDeadline(func, Date.now() + timeoutMs, retryIntervalMs);
  },

  /**
   * Calls the given function and retries it if an error is thrown. This will continue to retry
   * every retryIntervalMs until the deadline timestamp is reached.
   */
  withDeadline: async (func, deadline, retryIntervalMs = 500) => {
    try {
      return await func();
    } catch (error) {
      if (Date.now() < deadline - retryIntervalMs) {
        await Sleep.for(retryIntervalMs);
        console.log(`Retrying failed operation with error: ${error}`);
        return Retry.withDeadline(func, deadline, retryIntervalMs);
      }
      console.error(`Rejecting failed operation past deadline: ${error}`);
      return Promise.reject(error);
    }
  },
};

export default Retry;
